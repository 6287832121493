import React, { useState ,useRef,useEffect} from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { categorydata } from "../data";
import add from "../images/add.png";
import location1 from "../images/location_white.png";
import { Avatar, Grow, IconButton,  MenuItem, TextField} from "@mui/material";
import { axiosInstance } from "../api/APIs";
import { districts } from "../data";
import drop from "../images/arrow_drop_down.svg";
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material';
import cancel from "../images/cancelBlack.png";
import phone from '../images/servicesphone.svg'
import whatsapp from '../images/whatsapp.png'
import { truncateString } from "../ReuseableCompounts/TruncateString";
import back from '../images/Back.svg'
import { Link } from 'react-router-dom';
import search from '../images/searchser.svg'
import ApartmentIcon from '@mui/icons-material/Apartment';
import SearchIcon from '@mui/icons-material/Search'; // Import the search icon
import { MdArrowBackIosNew } from "react-icons/md";
import DifferenceIcon from '@mui/icons-material/Difference';
import CancelIcon from '@mui/icons-material/Cancel';import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector } from 'react-redux';




const ServicesDetails = () => {


    const [searchCity, setSearchCity] = useState<string>("");
    const [Location, setLocation] = useState<string>("");
    const [cityVisable, setCityVisable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const location = useLocation();
    const Data = location.state?.data
    const category = location.state?.category
    const [bgShow, setBgShow] = useState<any>(category)
    const navigate = useNavigate()
    console.log(category);
    const [data, setData] = useState<any>(Data)
    const [open, setOpen] = useState(false);
    const [model, setModel] = useState<any>("")
var itemValue =""

    console.log(data);
    const growRef = useRef<HTMLDivElement | null>(null); // Create a ref for the Grow component
    const handleClickOutside = (event: MouseEvent) => {
        if (growRef.current && !growRef.current.contains(event.target as Node)) {
            event.stopPropagation();
            setCityVisable(false);
        }
    };
    
    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup the event listener on component unmount
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    function handlePost() {

        if (loginData.phone == "" || loginData.name =="" || loginData.email =="") {
            navigate('/contact')
          }
          else {
            navigate('/postservice')
          }
        navigate('/postservice')
    }

    async function handleServices(label: any) {
        setOpen(false);
        setBgShow(label)
        console.log("dis",Location)

        try {

            const response = await axiosInstance.get(`/services/categoryBased/userlist/${label}`)
            console.log(response.data.message);
            setData(response.data.message)


        } catch (error) {
            console.log("error", error)
        }
    }

    

    async function handleAdd(value: any) {
        setLoading(true);
        setBgShow(value);
    
        const body = {
            district: itemValue || Location,
            locality: searchCity,
            category: value
        };
    
        // Log the body object before serialization
        console.log("Request Body Before Serialization:", body);
    
        try {
            // Attempt to serialize the body
            JSON.stringify(body);
        } catch (error) {
            console.error("Serialization error:", error);
            console.log("Body contents causing the issue:", {
                district: body.district,
                locality: body.locality,
                category: body.category
            });
            setLoading(false); // Ensure loading state is reset
            return; // Exit early if there's a serialization error
        }
    
        try {
            const response = await axiosInstance.post('/services/districtBased/service/filter', body);
            setData(response.data.message);
        } catch (e) {
            console.error("API call error:", e);
        } finally {
            setLoading(false);
        }
    }
    

    function handleLocation(value: any) {
        setCityVisable(!cityVisable)
        setLocation(value)
        itemValue= value
        setCityVisable(false)
        handleAdd(bgShow)

    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchCity(event.target.value);
        setCityVisable(true)
    };

    function handleViewDetails(value: any) {
        console.log(value)
        setModel(value)
        setOpen(true)
    }
    const handleClose = () => {


        setOpen(false);
    }
    

    const handleClick = () => {
        console.log('Button clicked');
        const phoneNumber = model.mobile_no;
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };
    

    return (
        <div className='bg-[#F5F4F8] w-full flex flex-col mt-1    relative'>
            <div className='lg:mx-[15%] custom-xl:mx-[25%] bg-[#fff] lg:rounded-t-2xl pt-1   lg:mt-12'>
            <Box 
            sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginLeft:"10px",
                mt: {
                    xs: 1,   // margin-top for extra small screens
                    sm: 4,   // margin-top for small screens
                    md: 0,   // margin-top for medium screens
                    lg: 0,   // margin-top for large screens
                    xl: 0    // margin-top for extra large screens
                },            }}
        >
                        <div className='flex flex-col gap-2 '>
                            <p className='text-xl font-bold mt- msm:hidden md:flex  items-center'>   <IconButton 
    onClick={() => navigate(-1)} // Inline navigation logic

  >
    <MdArrowBackIosNew className='w-8 h-8  md:flex msm:flex ' />
    
  </IconButton> Service Providers</p>
                            <p className='msm:hidden  md:flex'>Select a service to see available providers</p>
                            <IconButton 
    onClick={() => navigate(-1)} // Inline navigation logic

  >
    <MdArrowBackIosNew className='w-8 h-8 msm:mb-1 md:hidden msm:flex ' />
    
  </IconButton>
  
                        </div>
                        <div 
    onClick={handlePost} style={{cursor:"pointer"}} 
    className='flex right-0  items-center bg-[#FBB337] md:p-3 msm:p-2 md:mt-5 sm:mt- rounded-l-full md:gap-3 msm:gap-1 font-semibold'
>
    <img 
        src={add} 
        className='w-3 h-3 md:w-8 md:h-8' // Set image size for mobile and desktop
        alt="Add" 
    />
    <div className='text-white text-base msm:text-sm md:text-lg'> 
        Add Your Service
    </div>
</div>
                        {/* <div onClick={handlePost} className='flex right-0 msm:mb-5  items-center bg-[#FBB337] md:p-3 msm:p-2 md:mt-10 sm:mt- rounded-l-full md:gap-3 msm:gap-1 msm:text-base sm:text-base md:text-lg font-semibold'>
                            <img src={add } />
                            <div className='text-white'>Add Your Service</div>
                        </div> */}
                    </Box>
                    <Box 
            sx={{ 
                display: 'flex', 
                gap: 1, 
                overflowX: 'auto', 
                maxWidth: '100%', 
                whiteSpace: 'nowrap', 
                marginLeft:"10px"
            }}
        >
            {categorydata.map((item) => (
                <Box key={item.id} sx={{ flexShrink: 0 }}>
                    <Typography
                        onClick={() => {
                            if (Location !== "") {
                                handleAdd(item.value);
                            } else {
                                handleServices(item.value);
                            }
                        }}
                        sx={{
                            py: {
                                xs: 1,   // padding-y for extra small screens
                                sm: 1,   // padding-y for small screens
                                md: 1,   // padding-y for medium screens
                                lg: 1,   // padding-y for large screens
                                xl: 1    // padding-y for extra large screens
                            },
                            mt: {
                                xs: 1,   // margin-top for extra small screens
                                sm: 1,   // margin-top for small screens
                                md: 1,   // margin-top for medium screens
                                lg: 1,   // margin-top for large screens
                                xl: 1    // margin-top for extra large screens
                            },
                            px: {
                                xs: 2,   // padding-x for extra small screens
                                sm: 2,   // padding-x for small screens
                                md: 2,   // padding-x for medium screens
                                lg: 2,   // padding-x for large screens
                                xl: 2    // padding-x for extra large screens
                            },
                            borderRadius: '30px',
                            whiteSpace: 'nowrap',
                            fontSize:{
                                xs: "12px",   // margin-top for extra small screens
                                sm: "15px",   // margin-top for small screens
                                md: "17px",   // margin-top for medium screens
                                lg: "17px",   // margin-top for large screens
                                xl: "17px"    // margin-top for extra large screens
                            },
                            backgroundColor: bgShow === item.value ? '#0cb7d1' : '#D9D9D9',
                            color: bgShow === item.value ? 'white' : 'black',
                            fontWeight: 600,
                            cursor:"pointer",
                            fontFamily: 'K2D, sans-serif', // Add K2D font family
                            // '&:hover': {
                            //     backgroundColor: bgShow === item.value ? '#0D4A9A' : '#C0C0C0',
                            //     cursor: 'pointer',
                            // },
                        }}
                    >
                        {item.value}
                    </Typography>
                </Box>
            ))}
        </Box>
                   
<Box 
            sx={{ 
                border: '1px solid #000', 
                opacity: 0.1,     
                marginLeft:"10px",
                mt: {
                    xs: 1,   // margin-top for extra small screens
                    sm: 2,   // margin-top for small screens
                    md: 2,   // margin-top for medium screens
                    lg: 2,   // margin-top for large screens
                    xl: 2    // margin-top for extra large screens
                },                mx: 2                       
            }} 
        />
                    <Box  sx={{
                                        marginLeft:"10px",
                padding: {
                    xs: 0,      // No padding for extra small screens
                    sm: 2,     // 20px padding for small screens
                    md: 2,      // 2px padding for medium screens
                    lg: 2,      // 2px padding for large screens
                    xl: 2,      // 2px padding for extra-large screens
                },
                position: 'relative', // Equivalent to the 'relative' class
            }} >

                        <div className={` flex  items-center  rounded-xl w-[100%] relative`}>
                        <TextField
                        select
                        placeholder='tev'
                        label={Location ? "" : "Choose district"} // Placeholder effect
                        variant="outlined"
                        
                        sx={{
                            borderRadius: 0, // Remove border radius
 width:{xs:"150px",sm:"170px",lg:"180px"},
                            marginTop:"10px",
                            height: '50px', // Set height to 100px
                            '.MuiOutlinedInput-root': {
                                height: '40px',
                                borderRadius: 0,
                                borderTopLeftRadius:"10px",
                                borderBottomLeftRadius:"10px", // Ensure no border radius on the input
                                // Make sure input height is consistent
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '.MuiSelect-icon': {
                                    display: 'none', // Hide the dropdown arrow
                                  },
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                             fontSize:{xs:"12px",sm:"13px",lg:"15px"},
                                marginTop:"-4%",
                                fontWeight:700,
                            },
                        }}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                        width: 250,
                                    },
                                },
                            },
                        }}
                        InputProps={{
                            style: {
                                backgroundColor: '#E4E0EE',
                                border: 'none',
                              
                            },
                        }}
                        onClick={() => setCityVisable(!cityVisable)}
                        // className="w-[20%]"
                        value={Location}
                    >
                        {districts.map((district) => (
                            <MenuItem key={district} value={district} onClick={() => handleLocation(district)}>
                                {district}
                            </MenuItem>
                        ))}
                    </TextField>
                           
                            <input onChange={handleSearch} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleAdd(bgShow)
                                }
                            }} placeholder="Search Your location" value={searchCity}  
                               className="bg-[#F5F4F8] rounded-r-2xl px-1 w-[100%] py-2 border border-[#D1D1D1] outline-none text-black focus:border-[#F5F4F8] transition duration-200                             text-[14px] md:text-[15px] "
                            />
                        <Button  
                    // onClick={() => handleAdd(bgShow)} 
                    onClick={() => {
                        if (Location !== "") {
                            handleAdd(bgShow);
                                                } else {
                            handleServices(bgShow);
                        }
                    }}
                    variant="contained" 
                    color="primary"

                    sx={{  minWidth: 'auto', padding: '5px',position:"absolute",right:10,borderRadius:"60px" ,                    color:"#02a7e4"
                    }} // Adjust padding for a compact button
                >
                    <SearchIcon sx={{color:"#fff"}} />
                </Button></div>
 
 
                    </Box>
                    <div className='w-[100%] relative ml-[10px]'>
                    <Box sx={{ fontSize: { xs: '1rem', md: '1.25rem' }, fontWeight: 'bold', ml: { xs: 1, md: 0 } }}>
    Available {bgShow}
</Box>                        <div className={`flex `}>
                        <Box sx={{overflowY:"auto"}} className='custom-scrollbar h-[80vh] lg:w-[50%] md:w-[90%] msm:w-[100%] sm:w-[100%] md:mx-[15%] msm:mx-1 sm:mx-[5%] lg:mx-0 lg:ml-3 gap-2 flex flex-col mt-4' >
                        {loading ? (
                    <CircularProgress />
                ) : Array.isArray(data) && data.length > 0 ? (                                    data.map((item: any, index: any) => {
                                        const value = truncateString(item.locality, 10)
                                        return (

                                            <Box  display="flex" alignItems="center"  bgcolor="#F5F4F8" key={index} sx={{borderRadius:"10px",padding:"3px",margin:"2px",boxShadow:1,}}>
                                            <Box sx={{width:"15%",display:"flex",alignItems:"center",justifyContent:"center"}} >
                                                {item.business_profile && item.business_profile.length > 0 ? (
                                                    <img
                                                        alt={item.user_name}
                                                        src={item.business_profile}
                                                        style={{ borderRadius: "10px" }}
                                                        className='object-cover w-[50px] h-[50px] ' // Adjust styles as needed
                                                    />
                                                ) : (
                                                    <Box width={56} height={56} bgcolor="#ff4f8" display="flex" alignItems="center" justifyContent="center">
                                                        <ApartmentIcon sx={{ color: "#d9d9d9", fontSize: "2.25rem" }} />
                                                    </Box>
                                                )}
                                            </Box>
                                        
                                            <Box
                                            sx={{width:"55%"}}
                                                // width={{ xs: '50%', sm: '50%', md: '50%', lg: '40%', xl: '40%' }}
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                ml={{ xs:1 , sm: 2 }}
                                            >
                                                <Box fontWeight="bold"        whiteSpace="nowrap"
  width="100%"  
    overflow="hidden"
    textOverflow="ellipsis" fontSize={{ xs: '15px', sm: '15px', md: '18px', lg: '18px', xl: '18px' }}>
                                                    {item.user_name}
                                                </Box>
                                                <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
                                                    <img src={location1} alt="Location" />
                                                    <Box color="#8E8E8E" fontSize={{ xs: '0.75rem', sm: '1rem' }}>
                                                        {value}
                                                    </Box>
                                                    <Box color="#8E8E8E" fontSize={{ xs: '0.75rem', sm: '1rem' }}>
                                                        ,{item.district}
                                                    </Box>
                                                </Box>
                                                <Box fontSize={{ xs: '0.75rem', sm: '1rem' }}>
                                                    {item.service_category}
                                                </Box>
                                            </Box>
                                        
                                            <Box  borderRadius="8px"                                              sx={{width:"30%",display:"flex",justifyContent:"center",alignItems:"center"}}
                                            >
                                                <Button onClick={() => { handleViewDetails(item); }}  sx={{borderRadius:"8px", padding: { xs: '8px', sm: '16px', md: '16px' } ,fontSize:"10px" ,                            fontFamily: 'K2D, sans-serif', 
backgroundColor:"#0CB7D1",color:"#fff"}}>
                                                    View Details
                                                </Button>
                                            </Box>
                                        </Box>


                                        )
                                    })
                                ) : (
                                    <p className='text-center mt-4'>No {bgShow} available at the moment.</p>
                                )}
                                  
  
                            </Box>
                            <div className={`border-1 md:hidden   sm:hidden msm:hidden border-black  ${Array.isArray(data) &&  data.length > 0 ? "flex" : "hidden"}`}></div>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-description"
                            >
                                <Box sx={      {  boxShadow: 2}}
                                >
                                  <Box
    sx={{
        position: 'absolute',
        left: { xs: '10%', md: '33%', lg: '58%',xl:"50%" },
        transform: { xs: 'translateY(-50%)', lg: 'translateX(-16.67%)', xl: 'translateX(25%)' },
        top: { xs: '50%', sm: '50%',lg:"10%",xl:"20%" },
        width: { xs: '80%', md: '80%', lg: '40%', xl: '30%' },
        backgroundColor: '#F5F4F8',
        borderRadius: '12px',
        boxShadow: 3,
        // padding: 2,
        // height:{ xs: '70%',sm:"50%", md: '60%', lg: '88%', xl: '80%' }
    }}
>
                                        <div className='bg-[#E4E7F4] flex p-3 gap-3 items-center' style={{borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}}>
                                            <div>
                                            {model.business_profile ? (
                <Avatar
                    alt={model.user_name}
                    src={model.business_profile}
                    sx={{ 
                        width: 56, 
                        height: 56, 
                        borderRadius: '10px' // Adjust radius if needed
                    }} 
                />
            ) : (
                <Box 
                    sx={{ 
                        width: 56, 
                        height: 56, 
                        bgcolor: "#fff", 
                        display: "flex", 
                        borderRadius:"10px",
                        alignItems: "center", 
                        justifyContent: "center" 
                    }}
                >
                    <ApartmentIcon sx={{ color: "#d9d9d9", fontSize: "2.25rem" }} />
                </Box>
            )}
                                            </div>
                                            <div className='flex flex-col items-start justify-start'>
                                            <Box  sx={{
        fontSize: '1.25rem',
        fontWeight: '600',
        width:"80%",
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 3, // Limit to 3 lines
        maxHeight: '4.5em', // Adjust based on line height
        lineHeight: '1.5em', // Adjust line height for better spacing
    }}>
    {model.user_name}
</Box>                                                <div className='flex items-center gap-1'>
                                                    <img src={location1} alt="Location" />
                                                    <p className='text-[#8E8E8E]'>{model.district}</p>
                                                </div>
                                                <p>{model.service_category}</p>
                                            </div>
                                            <div onClick={handleClose} className='absolute right-2 top-2 cursor-pointer'>
                                                <img src={cancel} alt="Cancel" />
                                            </div>
                                        </div>
                                        <div className='flex gap-2 bg-[#F5F4F8] justify-between msm:p-2 md:p-3'>
                                            <Button
                                             onClick={() => {
                                                setDialogOpen(true);
                                            }} 
                                                startIcon={<img src={phone} alt="Phone" />}
                                                sx={{ flex: 1, borderRadius: 2, py: 1 ,backgroundColor:"#0CB7D1",color:"#fff",   fontFamily: 'K2D, sans-serif', }}
                                            >
                                                Direct Call
                                            </Button>
                                            <Button
            color="success"
            startIcon={<img src={whatsapp} alt="WhatsApp" />}
            sx={{
                flex: 1,
                borderRadius: 2,
                py: 1,
                backgroundColor: "#23A55A",
                color: "#fff",
                fontFamily: 'K2D, sans-serif',
            }}
            onClick={handleClick}
        >
            Chat
        </Button>
                                        </div>
                                        
<Modal
        open={dialogOpen} onClose={() => setDialogOpen(false)}
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
      >
        <Box
        style={{background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)',fontSize:"13px" }}
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width:300,
            borderRadius:"20px",
            bgcolor: "background.paper",
            boxShadow: 24,
            display:"flex",
            flexDirection:"row",
            p: 4,
            textAlign: 'center'
          }}
        >

          

 <Typography variant="h6" sx={{color:"#fff"}}>
                    
                        {model.mobile_no}
                </Typography>  
               <Button 
              href={`tel:${model.mobile_no}`}
                
                sx={{ color: "#fff" }}

            >
                <DifferenceIcon sx={{ fontSize: "20px" }} />
            </Button>

          
           
          <IconButton
                                
onClick={() => setDialogOpen(false)}            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              color: "#000",
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      </Modal>
                                        <div className='px-3'>
                                             <Typography  component="div" sx={{   fontWeight:500,     marginY:"10px ",                          fontFamily: 'K2D, sans-serif',                  fontSize:{
                                xs: "16px",  
                                sm: "18px",  
                                md: "18px",  
                                lg: "18px",  
                                xl: "18px"    
                            }}}  >
Service Available Localities                                            </Typography>
                                            <Typography sx={{                            fontSize:{
                                xs: "17px",  
                                sm: "17px",  
                                md: "19px",  
                                lg: "19px",  
                                xl: "19px"    
                            },fontWeight:400, marginBottom:"10px" }} >{model.locality}</Typography>
                                        </div>
                                        <div className='px-3' >
                                           <Typography  component="div" sx={{   fontWeight:500,     marginY:"10px ",                          fontFamily: 'K2D, sans-serif',                  fontSize:{
                                xs: "16px",  
                                sm: "18px",  
                                md: "18px",  
                                lg: "18px",  
                                xl: "18px"    
                            }}}  >
                                                Job Description 
                                            </Typography>
                                            <Typography sx={{                            fontSize:{
                                xs: "12px",  
                                sm: "15px",  
                                md: "17px",  
                                lg: "13px",  
                                xl: "13px"    
                            },fontWeight:400,  maxHeight:"180px",     overflowY: 'auto'}}>{model.job_description} </Typography>
                                        </div>
                                        <div className='px-3'>
                                           <Typography  component="div" sx={{   fontWeight:500,     marginY:"10px ",                          fontFamily: 'K2D, sans-serif',                  fontSize:{
                                xs: "16px",  
                                sm: "18px",  
                                md: "18px",  
                                lg: "18px",  
                                xl: "18px"    
                            }}} >
                                                Contact Number
                                            </Typography>
                                            <Typography sx={{                            fontSize:{
                                xs: "14px",  
                                sm: "15px",  
                                md: "17px",  
                                lg: "13px",  
                                xl: "13px"    
                            },fontWeight:400, marginBottom:"10px" }} >{model.mobile_no}</Typography>
                                        </div>
                                     
                                    </Box>
                                </Box>
                            </Modal>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default ServicesDetails