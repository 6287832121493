import { useEffect, useState } from "react";
import home from "../../images/tabbar/Home.png"
import chat from "../../images/tabbar/chat.png"
import profile from "../../images/tabbar/profile.png"
import settings from "../../images/tabbar/settings.png"
import homew from "../../images/tabbar/home white.png"
import chatw from "../../images/tabbar/Chat White.png"
import profilew from "../../images/tabbar/profiel white.png"
import settingsw from "../../images/tabbar/setting white.png"

import { useLocation, useNavigate } from "react-router-dom";
import { useStepperContext } from '../PostForm/component/StepperContext';
import { resetUserLoginData } from '../../slices/userDataSlice';
import { resetUser } from '../../slices/filterSlice';
import { axiosInstance } from '../api/APIs';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';


const SOCKET_URL = 'https://vattaram.in';


const MNavbar: React.FC = () => {

  const dispatch=useDispatch()
  const {resetForm}=useStepperContext()
  const loginData = useSelector((state: any) => state.userData.userLoginData)
  const [Data,setData]=useState<any>([])


  useEffect(()=>{
    const socket = io(SOCKET_URL);
    fetch()

    socket.on('connect', () => {
        console.log('connected');
      });
  
      // Handle incoming 'userDetails' event
      socket.on('userDetails', (data) => {
        const deviceExists = data.logged_device.some((device:any) => device.id === loginData.Id);
        console.log("exists",deviceExists)
        if (data.userId ===loginData?.user_id && !deviceExists) {
            dispatch(resetUserLoginData())
            dispatch(resetUser())
            Navigate('/')
            resetForm()
        }
      });
  
      // Clean up the socket connection on component unmount
      return () => {
        socket.off('connect');
        socket.off('userDetails');
        socket.disconnect();
      };
},[loginData])

const fetch = async () => {
    try {
        if (loginData?.user_id) {  // Ensure loginData and user_id exist before making the request
            const response = await axiosInstance(`/user/login/deviceGet/${loginData.user_id}`);
           
            if(response.status===200){
                setData(response.data.Data)
            }
        }
    } catch (error) {
        console.error("Error fetching device history:", error);
    }
};



const Navigate = useNavigate()
const location = useLocation();

  const [activeOption, setActiveOption] = useState<string>("");
  const [isTextChanged, setIsTextChanged] = useState<boolean>(false);

  const setActiveOptionFromPath = () => {
    const path = location.pathname;
    switch (path) {
      case "/":
        setActiveOption("home");
        break;
      case "/chatscreen":
        setActiveOption("Chat");
        break;
      case "/services":
        setActiveOption("services");
        break;
      case "/profile":
        setActiveOption("Profile");
        break;
      default:
        setActiveOption("");
        break;
    }
  };

  useEffect(() => {
    setActiveOptionFromPath();
  }, [location]);

  const handleClick = (option: string) => {
    if (activeOption !== option) {
      switch (option) {
        case "home":
          Navigate("/");
          break;
        case "Chat":
          Navigate("/chatscreen");
          break;
        case "services":
          Navigate("/services");
          break;
        case "Profile":
          Navigate("/profile");
          break;
        default:
          break;
      }
      setActiveOption(option);
      setIsTextChanged(true);
      setTimeout(() => {
        setIsTextChanged(false);
      }, 1000); // Reset the text change effect after 1 second
    }
  };

  const handleProfile = () => {
    if (loginData.phone !== "") {
      Navigate('/profile')
    }
    else {
      Navigate('/contact',{state:{post:false}})
    }
  }
  const handlemessage = () => {
    if (loginData.phone !== "") {
      Navigate('/chatscreen')
    }
    else {
      Navigate('/contact',{state:{post:false}})
    }
  }

  const homeActiveImage = home; // White image for active state
  const homeInactiveImage = homew; 
  
  const chatActiveImage = chat; // White image for active state
  const chatInactiveImage = chatw;  
   const setActiveImage = settings; // White image for active state
  const setInactiveImage = settingsw; 
  const profileActiveImage = profile; // White image for active state
  const profileInactiveImage = profilew; 


  return (
    <div className="h-15 flex items-center justify-center">
      <div className="relative mx-auto rounded-full p-[15px] w-[100vw] bg-[#E4E0EE] flex ">
        <div
          id="btn"
          className={`absolute top-0 h-10 items-center justify-center mx-2 my-2 rounded-full transition-all duration-1000 ease-in-out ${
            activeOption === "home"
              ? "left-[1%] bg-[#1063b5] text-white "
              : activeOption === "Chat"
              ? "left-[23%] bg-[#1063b5] text-white"
              : activeOption === "services"
              ? "left-[47%] bg-[#1063b5] text-white"
              : activeOption === "Profile"
              ? "left-[72%] bg-[#1063b5] text-white"
              : ""
          }`}
          style={{ width: "100px" }}
        ></div>
        <div
          onClick={() => handleClick("home")}
          className={`nav-link flex flex-col items-center justify-center w-[25%] text-center p-1 outline-none cursor-pointer relative z-10 ${
            activeOption === "home" ? "text-white transition-all duration-1000 ease-in-out" : "text-[#1063b5]"
          }`}
        >
          <div className="flex flex-row gap-1">
          <img 
              src={activeOption === "home" ? homeInactiveImage : homeActiveImage} 
              className="w-5 h-5" 
              alt="Home" 
            />   {activeOption === "home" && (
              <div
                className={`text-sm  transition-opacity duration-1000 ease-in-out ${
                  isTextChanged ? "opacity-0" : "opacity-100"
                }`}
              >
                Home
              </div>
            )}
          </div>
        </div>
  
        <div
        onClick={handlemessage}          
        className={`nav-link flex flex-col items-center justify-center w-[25%] text-center p-1 outline-none  cursor-pointer relative z-10 ${
            activeOption === "Chat" ? "text-white" : "text-[#1063b5]"
          }`}
        >
          <div className="flex flex-row gap-1">
          <img 
              src={activeOption === "Chat" ? chatInactiveImage : chatActiveImage} 
              className="w-5 h-5" 
              alt="Home" 
            />           {activeOption === "Chat" && (
              <div className="text-sm ">Chat</div>
            )}
          </div>
        </div>
        <div
          onClick={() => handleClick("services")}
          className={`nav-link flex flex-col items-center justify-center w-[29%] p-1  text-center outline-none  cursor-pointer relative z-10 ${
            activeOption === "services" ? "text-white" : "text-[#1063b5]"
          }`}
        >
          <div className="flex flex-row gap-1">
          <img 
              src={activeOption === "services" ? setInactiveImage : setActiveImage} 
              className="w-5 h-5" 
              alt="Home" 
            /> {activeOption === "services" && (
              <div className="text-sm ">services</div>
            )}
          </div>
        </div>
        <div
onClick={handleProfile}       
className={`nav-link flex flex-col items-center justify-center w-[25%] p-1  text-center outline-none  cursor-pointer relative z-10 ${
  activeOption === "Profile" ? "text-white" : "text-[#1063b5]"
          }`}
        >
          <div className="flex flex-row gap-1">
          <img 
              src={activeOption === "Profile" ? profileInactiveImage : profileActiveImage} 
              className="w-5 h-5" 
              alt="Home" 
            />             {activeOption === "Profile" && (
              <div className="text-sm  ">Profile</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MNavbar;


