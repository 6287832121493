// // import React, { useState, useEffect, useRef } from 'react';
// // import { useSelector } from 'react-redux';
// // import {
// //   GoogleMap,
// //   Marker,
// //   InfoWindow,
// //   useJsApiLoader,
// //   Polygon
// //   } from '@react-google-maps/api';
// // import mapIn from './images/mapIn.png';
// // import Filter from './filter/Filter';
// // import SortBy from './sortBy/SortBy';
// // import { CiCircleList } from "react-icons/ci";
// // import { TfiMapAlt } from "react-icons/tfi";
// // import { BsSortUp } from "react-icons/bs";
// // import { BiFilterAlt } from "react-icons/bi";
// // import { MdArrowBackIosNew } from "react-icons/md";
// // import { Link } from 'react-router-dom';
// // import Scrollmini from './Scrollmini';
// // import Scroll from './Scroll';
// // import { Box } from '@mui/material';
// // import redpin from "../compounts/images/pinred.png"
// // import bluepin from "../compounts/images/pinblue.png"
// // import { formatPrice } from '../compounts/ReuseableCompounts/formatepricemap';


// // interface MapProps {
// //   children: React.ReactNode;
// //   setValue: any
// //   setSortOpen: any
// //   sortOpen: any
// //   setSelectedPostId: (postId: number) => void; // Add this line}
// //   value:number
// // }

// // interface MarkerData {
// //   title: string;
// //   latitude: number;
// //   longitude: number;
// //   image?: string;
// //   builtup: string;
// //   price: string;
// //   district: string;
// //   postid: number;

// // }

// // const Map: React.FC<MapProps> = ({ children, setValue, sortOpen, setSortOpen,setSelectedPostId,value }) => {
// //   const [isMapVisible, setIsMapVisible] = useState<boolean>(true);
// //   const [isListVisible, setIsListVisible] = useState<boolean>(false);
// //   const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
// //   const [activeButton, setActiveButton] = useState<string>('Map');
// //   const [chat, setChat] = useState<any>()
// //   const [filterProperty, setFilterProperty] = useState<string[]>([]);
// //   const [filterLoaction, setfilterLoaction] = useState<any[]>([])
// //   const [filterPrice, setFilterPrice] = useState<any>(true)
// //   const [loading, setLoading] = useState<boolean>(false)
// //   const [selectedPostId2, setSelectedPostId2] = useState<number | null>(null);

// //   const kb = false


// //   const [mapCenter, setMapCenter] = useState<
// //     google.maps.LatLngLiteral | undefined
// //   >(undefined);
// //   const [markers, setMarkers] = useState<MarkerData[]>([]);
// //   const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
// //     null
// //   );
// //   const [addresses, setAddresses] = useState<string[]>([]);
// //   const [geofenceRegions, setGeofenceRegions] = useState<any[]>([]);
// //   const scrollViewRef = useRef<any>(null);

// //   const [boundingPolygon, setBoundingPolygon] = useState<google.maps.LatLngLiteral[]>([]);



// //   const users = useSelector((state: any) => state.usersInfo.users);
// //   const apiKey = 'AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE';
// //   const [polygons, setPolygons] = useState<google.maps.LatLngLiteral[][]>([]); // Define the type for polygons

// //   const { isLoaded } = useJsApiLoader({ googleMapsApiKey: apiKey });
// //   const bufferDistance = 0.01; // Define the distance around the user location

// //   const fetchAddress = async (latitude: number, longitude: number) => {
// //     // Replace with actual geocoding API call
// //     return 'Sample Address';
// //   };

// //   const fetchAddresses = async () => {
// //     const fetchedAddresses = await Promise.all(
// //       markers.map(async (marker) =>
// //         fetchAddress(marker.latitude, marker.longitude)
// //       )
// //     );
// //     setAddresses(fetchedAddresses);
// //   };
// //   useEffect(() => {
// //     if (users.length > 0) {
// //       const newMarkers: MarkerData[] = users.map((user: any) => ({
// //         title: `${user.bhk ? `${user.bhk} ` : ''}${user.property_type}`,
// //         latitude: parseFloat(user.latitude),
// //         longitude: parseFloat(user.longitude),
// //         image: user.images?.find((img: any) => img.isCover)?.uri,
// //         builtup: `${user.built_up_area} | ${user.PriceperSqft ? `${user.PriceperSqft}/sqft` : ''}`,
// //         price: user.cost || user.price,
// //         district: `${user.sublocality}, ${user.district}`,
// //         postid: `${user.post_id}`,
// //       }));

// //       setMarkers(newMarkers);
// //       setMapCenter({
// //         lat: newMarkers[0]?.latitude || 0,
// //         lng: newMarkers[0]?.longitude || 0,
// //       });

// //       // Calculate bounding polygon coordinates
// //       const latitudes = newMarkers.map(marker => marker.latitude);
// //       const longitudes = newMarkers.map(marker => marker.longitude);

// //       const minLat = Math.min(...latitudes);
// //       const maxLat = Math.max(...latitudes);
// //       const minLng = Math.min(...longitudes);
// //       const maxLng = Math.max(...longitudes);

// //       // Create a bounding polygon (rectangle)
// //       const bufferDistance = 0.01; // Adjust this value for the buffer around the bounds
// //       const boundingCoords = [
// //         { lat: maxLat + bufferDistance, lng: minLng - bufferDistance }, // NE
// //         { lat: maxLat + bufferDistance, lng: maxLng + bufferDistance }, // NW
// //         { lat: minLat - bufferDistance, lng: maxLng + bufferDistance }, // SW
// //         { lat: minLat - bufferDistance, lng: minLng - bufferDistance }, // SE
// //         { lat: maxLat + bufferDistance, lng: minLng - bufferDistance }, // Closing the polygon
// //       ];

// //       setBoundingPolygon(boundingCoords);
// //     } else {
// //       setMapCenter({ lat: 11.1271, lng: 78.6569 });
// //       setMarkers([]);
// //       setBoundingPolygon([]); // Reset polygon
// //     }
// //   }, [users]);



// //   const handleMarkerClick = (index: number) => {
// //     setSelectedMarkerIndex(index);
// //     setMapCenter({
// //       lat: markers[index].latitude,
// //       lng: markers[index].longitude,
// //     });

// //     scrollViewRef.current?.scrollTo({ x: index * 370, animated: true });

// //     const selectedMarker = markers[index];
// //     setSelectedPostId(selectedMarker.postid);
// //     setSelectedPostId2(selectedMarker.postid);


// //   };


// //   function toggleFilterVisibility() {
// //     setIsMapVisible(false);
// //     setIsListVisible(true);
// //     setIsFilterVisible(false)
// //     setSortOpen(false)
// //     setActiveButton('List');


// //   }

// //   const [isOn, setIsOn] = useState(true);

// //   // Step 2: Create a function to handle the toggle
// //   const toggle = () => {
// //     setIsOn(prevState => !prevState);
// //   };
// //   const toggleMapVisibility = (buttonId: string) => {

// //     setIsMapVisible(true);
// //     setIsListVisible(false);
// //     setIsFilterVisible(false)
// //     setSortOpen(false)
// //     setActiveButton(buttonId === 'Map' ? 'Map' : 'List');
// //   };

// //   const toggleListVisibility = (buttonId: string) => {
// //     setIsMapVisible(false);
// //     setIsListVisible(true);
// //     setIsFilterVisible(false)
// //     setSortOpen(false)
// //     setActiveButton(buttonId === 'List' ? 'List' : 'Map');
// //   };

// //   const togglefilterVisibility = (buttonId: any) => {
// //     setIsMapVisible(false);
// //     setIsListVisible(false);
// //     setIsFilterVisible(true)
// //     setSortOpen(false)
// //     setActiveButton(buttonId === 'Filter' ? 'Filter' : "Map")
// //   }

// //   const toggleSortVisibility = (buttonId: any) => {
// //     setIsMapVisible(false);
// //     setIsListVisible(false);
// //     setIsFilterVisible(false)
// //     setSortOpen(true)
// //     setActiveButton(buttonId === 'Sort' ? 'Sort' : "Map")
// //   }

// //   function handleSort() {
// //     // console.log("hello");
// //     // setIsFilterVisible(false);
// //     setSortOpen(false)
// //     setIsMapVisible(false);
// //     setIsListVisible(true);
// //     setIsFilterVisible(false)
// //     setActiveButton('List');
// //     // setActiveButton(buttonId === 'Sort' ? 'Sort' : "Map")


// //   }
// //   const chennaiBoundary = [
// //     { lat: 13.067439, lng: 80.237617 },
// //     { lat: 13.074309, lng: 80.240100 },
// //     { lat: 13.078520, lng: 80.254440 },
// //     { lat: 13.089920, lng: 80.260610 },
// //     { lat: 13.105290, lng: 80.258620 },
// //     { lat: 13.118290, lng: 80.239900 },
// //     { lat: 13.115600, lng: 80.219020 },
// //     { lat: 13.093400, lng: 80.216900 },
// //     { lat: 13.072240, lng: 80.224000 },
// //     { lat: 13.067439, lng: 80.237617 } // Closing the polygon
// //   ];

// //   const redMarkerIcon = redpin; // Replace with your red marker icon URL
// //   const blueMarkerIcon = bluepin; // Replace with your blue marker icon URL

// //   return (
// //     <div className="relative">
// //       <div className=" mt-4 lg:flex">
// //         {/* Map View */}
// //         <div className={`lg:flex lg:w-[48%] xl:w-[65%] h-[96vh] ${isMapVisible ? "flex" : "hidden"}`}>
// //           <Link to={'/'}>
// //             <MdArrowBackIosNew className='absolute w-8 h-8 top-2 left-1 z-40 lg:hidden md:flex' />
// //           </Link>
// //           {isLoaded && mapCenter && (
// //             <GoogleMap
// //               center={mapCenter}
// //               zoom={13}

// //               mapContainerStyle={{ height: '100%', width: '100%' }}
// //             >
// //              {markers.map((marker, index) => {
// //   const isSelected = selectedMarkerIndex === index; 
// //   const markerIcon = isSelected ? blueMarkerIcon : redMarkerIcon;

// //   return (
// //     <Marker
// //       title={marker.price}
// //       key={index}

// //       icon={{
// //         url: markerIcon,
// //         scaledSize: new google.maps.Size(40, 40), // Adjust size here (width, height)
// //       }}
// //       label={{
// //         text:formatPrice(marker.price),
// //         color: '#000',
// //         fontSize: '10px',
// //         fontWeight: 'bold',
// //       }}
// //       position={{ lat: marker.latitude, lng: marker.longitude }}
// //       onClick={() => handleMarkerClick(index)} // Handle click
// //     />
// //   );
// // })}

// //     {boundingPolygon.length > 0 && (
// //                 <Polygon
// //                   paths={boundingPolygon}
// //                   options={{
// //                     fillColor: 'lightblue',
// //                     fillOpacity: 0.5,
// //                     strokeColor: 'blue',
// //                     strokeOpacity: 1,
// //                     strokeWeight: 2,
// //                   }}
// //                 />
// //               )}

// //               {/* {selectedMarkerIndex !== null && (
// //               <div className='w-[50px] h-[50px]'>  <InfoWindow
// //                   position={{
// //                     lat: markers[selectedMarkerIndex].latitude,
// //                     lng: markers[selectedMarkerIndex].longitude,
// //                   }}

// //                   onCloseClick={() => setSelectedMarkerIndex(null)}
// //                 >
// //                   <div
// //                     className={`p-2 flex gap-2.5 transition-height duration-300 ${selectedMarkerIndex === null ? 'lg:h-[35px]' : 'h-[auto]'
// //                       }`}
// //                   >
// //                     <div>
// //                       {markers[selectedMarkerIndex]?.image && (
// //                         <img className='lg:w-[180px] lg:h-[100%] rounded-md'
// //                           src={markers[selectedMarkerIndex].image}
// //                           alt="Property"

// //                         />
// //                       )}
// //                     </div>
// //                     <div className="">
// //                       <h4 className="font-bold text-[#252B5C]  text-lg">
// //                         {markers[selectedMarkerIndex]?.title}
// //                       </h4>
// //                       <p>{markers[selectedMarkerIndex]?.district}</p>
// //                       <p>Price: {markers[selectedMarkerIndex]?.price}</p>
// //                       <p>{markers[selectedMarkerIndex]?.builtup}</p>
// //                     </div>
// //                   </div>
// //                 </InfoWindow></div> 
// //               )} */}

// //             </GoogleMap>
// //           )}


// //         </div>

// //         {/* List View */}
// //         <div
// //           className={`xl:w-[55%]  lg:grid ${isListVisible ? 'msm:grid sm:grid' : 'hidden'
// //             } `}
// //         >
// //           {React.Children.map(children, (child, index) =>
// //             React.cloneElement(child as React.ReactElement, {
// //               isSelected: index === selectedMarkerIndex,
// //               onClick: () => handleMarkerClick(index),
// //             })
// //           )}
// //         </div>

// //         <div className={`lg:hidden ${isFilterVisible ? 'msm:grid sm:grid overflow-y-scroll' : 'hidden'}`}>
// //           <Filter kb={kb} setLoading={setLoading} toggleFilterVisibility={toggleFilterVisibility} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
// //         </div>
// //         <div className={` lg:hidden ${sortOpen ? 'msm:grid sm:grid' : 'hidden'}`}>
// //           <SortBy setValue={setValue} handleSort={handleSort} />
// //         </div>

// //       </div>

// //       {/* Buttons */}
// //       <div className="bg-transparent flex lg:hidden w-full z-60 fixed bottom-5 justify-center items-center">
// //       <div className="bg-[#F5F4F8] h-16 w-[95%] rounded-full border border-[#D6D3D1] flex justify-between items-center px-4">
// //         <div className="flex items-center border-r border-[#D6D3D1] flex-1 justify-center">
// //         <div className={`lg:hidden   rounded-2xl `} style={{display:"flex",flexDirection:"row"}} onClick={() => togglefilterVisibility('Filter')}>
// //             <BiFilterAlt className='h-8 w-8 ml-5' />
// //             <button className='text-lg  font-semibold'


// //             >
// //               Filter
// //             </button>
// //           </div>
// //         </div>
// //         <div className="flex items-center border-r border-[#D6D3D1] flex-1 justify-center">
// //         <button onClick={toggle} >
// //         {isOn ?  <div  className="lg:hidden    rounded-2xl " style={{display:"flex",flexDirection:"row"}} onClick={() => toggleListVisibility('List')}>
// //             {/* <div className={` ${activeButton === "Map" ? 'hidden' : "flex"} border-0.5 border-l border-[#80848E] h-full `}></div> */}
// //             <CiCircleList className='h-8 w-8 ml-8' />
// //             <button className='text-lg  font-semibold'


// //             >
// //               List
// //             </button>
// //           </div> : <div className="lg:hidden    rounded-2xl " style={{display:"flex",flexDirection:"row"}} onClick={() => toggleMapVisibility('Map')}>

// // <TfiMapAlt className='h-8 w-8 ml-8' />
// // <button className='text-lg  font-semibold'


// // >
// //   Map
// // </button>
// // </div>    }
// //       </button>
// //         </div>
// //         <div className="flex items-center flex-1 justify-center">
// //         <div className={`lg:hidden  rounded-2xl `}  style={{display:"flex",flexDirection:"row"}}onClick={() => toggleSortVisibility('Sort')}>

// // <BsSortUp className='h-8 w-8 ml-3' />
// // <button className='text-lg  font-semibold'


// // >
// //   Sort By
// // </button>
// // </div>
// //         </div>
// //       </div>
// //     </div>
// //     </div>
// //   );
// // };

// // export default Map;

// import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   GoogleMap,
//   Marker,
//   InfoWindow,
//   useJsApiLoader,
//   Polygon,
  
// } from '@react-google-maps/api';
// import mapIn from './images/mapIn.png';
// import Filter from './filter/Filter';
// import SortBy from './sortBy/SortBy';
// import { CiCircleList } from "react-icons/ci";
// import { TfiMapAlt } from "react-icons/tfi";
// import { BsSortUp } from "react-icons/bs";
// import { BiFilterAlt } from "react-icons/bi";
// import { MdArrowBackIosNew } from "react-icons/md";
// import { Link } from 'react-router-dom';
// import Scrollmini from './Scrollmini';
// import Scroll from './Scroll';
// import { Box } from '@mui/material';
// import redpin from "../compounts/images/pinred.png"
// import bluepin from "../compounts/images/pinblue.png"
// import { formatPrice } from '../compounts/ReuseableCompounts/formatepricemap';


// interface MapProps {
//   children: React.ReactNode;
//   setValue: any
//   setSortOpen: any
//   sortOpen: any
//   setSelectedPostId: (postId: number) => void; // Add this line}
//   value: number
// }

// interface MarkerData {
//   title: string;
//   latitude: number;
//   longitude: number;
//   image?: string;
//   builtup: string;
//   price: string;
//   district: string;
//   postid: number;

// }

// const Map: React.FC<MapProps> = ({ children, setValue, sortOpen, setSortOpen, setSelectedPostId, value }) => {
//   const [isMapVisible, setIsMapVisible] = useState<boolean>(true);
//   const [isListVisible, setIsListVisible] = useState<boolean>(false);
//   const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
//   const [activeButton, setActiveButton] = useState<string>('Map');
//   const [chat, setChat] = useState<any>()
//   const [filterProperty, setFilterProperty] = useState<string[]>([]);
//   const [filterLoaction, setfilterLoaction] = useState<any[]>([])
//   const [filterPrice, setFilterPrice] = useState<any>(true)
//   const [loading, setLoading] = useState<boolean>(false)
//   const [selectedPostId2, setSelectedPostId2] = useState<number | null>(null);

//   const kb = false


//   const [mapCenter, setMapCenter] = useState<
//     google.maps.LatLngLiteral | undefined
//   >(undefined);
//   const [markers, setMarkers] = useState<MarkerData[]>([]);
//   const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
//     null
//   );
//   const [addresses, setAddresses] = useState<string[]>([]);
//   const [geofenceRegions, setGeofenceRegions] = useState<any[]>([]);
//   const scrollViewRef = useRef<any>(null);

//   const [boundingPolygon, setBoundingPolygon] = useState<google.maps.LatLngLiteral[]>([]);
//   console.log(markers)


//   const users = useSelector((state: any) => state.usersInfo.users);
//   const apiKey = 'AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE';
//   const [polygons, setPolygons] = useState<google.maps.LatLngLiteral[][]>([]); // Define the type for polygons

//   const { isLoaded } = useJsApiLoader({ googleMapsApiKey: apiKey });
//   const bufferDistance = 0.01; // Define the distance around the user location

//   const fetchAddress = async (latitude: number, longitude: number) => {
//     // Replace with actual geocoding API call
//     return 'Sample Address';
//   };

//   const fetchAddresses = async () => {
//     const fetchedAddresses = await Promise.all(
//       markers.map(async (marker) =>
//         fetchAddress(marker.latitude, marker.longitude)
//       )
//     );
//     setAddresses(fetchedAddresses);
//   };
//   useEffect(() => {
//     if (users.length > 0) {
//       const newMarkers: MarkerData[] = users.map((user: any) => ({
//         title: `${user.bhk ? `${user.bhk} ` : ''}${user.property_type}`,
//         latitude: parseFloat(user.latitude),
//         longitude: parseFloat(user.longitude),
//         image: user.images?.find((img: any) => img.isCover)?.uri,
//         builtup: `${user.built_up_area} | ${user.PriceperSqft ? `${user.PriceperSqft}/sqft` : ''}`,
//         price: user.cost || user.price|| user.enter_lease_amount || user.lease_amount||user.monthly_rent||"",
//         district: `${user.sublocality}, ${user.district}`,
//         postid: `${user.post_id}`,
//       }));

//       setMarkers(newMarkers);
//       setMapCenter({
//         lat: newMarkers[0]?.latitude || 0,
//         lng: newMarkers[0]?.longitude || 0,
//       });

//       // Calculate bounding polygon coordinates
//       const latitudes = newMarkers.map(marker => marker.latitude);
//       const longitudes = newMarkers.map(marker => marker.longitude);

//       const minLat = Math.min(...latitudes);
//       const maxLat = Math.max(...latitudes);
//       const minLng = Math.min(...longitudes);
//       const maxLng = Math.max(...longitudes);

//       // Create a bounding polygon (rectangle)
//       const bufferDistance = 0.01; // Adjust this value for the buffer around the bounds
//       const boundingCoords = [
//         { lat: maxLat + bufferDistance, lng: minLng - bufferDistance }, // NE
//         { lat: maxLat + bufferDistance, lng: maxLng + bufferDistance }, // NW
//         { lat: minLat - bufferDistance, lng: maxLng + bufferDistance }, // SW
//         { lat: minLat - bufferDistance, lng: minLng - bufferDistance }, // SE
//         { lat: maxLat + bufferDistance, lng: minLng - bufferDistance }, // Closing the polygon
//       ];

//       setBoundingPolygon(boundingCoords);
//     } else {
//       setMapCenter({ lat: 11.1271, lng: 78.6569 });
//       setMarkers([]);
//       setBoundingPolygon([]); // Reset polygon
//     }
//   }, [users]);



//   const handleMarkerClick = (index: number) => {
//     setSelectedMarkerIndex(index);
//     setMapCenter({
//       lat: markers[index].latitude,
//       lng: markers[index].longitude,
//     });

//     scrollViewRef.current?.scrollTo({ x: index * 370, animated: true });

//     const selectedMarker = markers[index];
//     setSelectedPostId(selectedMarker.postid);
//     setSelectedPostId2(selectedMarker.postid);


//   };


//   function toggleFilterVisibility() {
//     setIsMapVisible(false);
//     setIsListVisible(true);
//     setIsFilterVisible(false)
//     setSortOpen(false)
//     setActiveButton('List');


//   }

//   const [isOn, setIsOn] = useState(true);

//   // Step 2: Create a function to handle the toggle
//   const toggle = () => {
//     setIsOn(prevState => !prevState);
//   };
//   const toggleMapVisibility = (buttonId: string) => {

//     setIsMapVisible(true);
//     setIsListVisible(false);
//     setIsFilterVisible(false)
//     setSortOpen(false)
//     setActiveButton(buttonId === 'Map' ? 'Map' : 'List');
//   };

//   const toggleListVisibility = (buttonId: string) => {
//     setIsMapVisible(false);
//     setIsListVisible(true);
//     setIsFilterVisible(false)
//     setSortOpen(false)
//     setActiveButton(buttonId === 'List' ? 'List' : 'Map');
//   };

//   const togglefilterVisibility = (buttonId: any) => {
//     setIsMapVisible(false);
//     setIsListVisible(false);
//     setIsFilterVisible(true)
//     setSortOpen(false)
//     setActiveButton(buttonId === 'Filter' ? 'Filter' : "Map")
//   }

//   const toggleSortVisibility = (buttonId: any) => {
//     setIsMapVisible(false);
//     setIsListVisible(false);
//     setIsFilterVisible(false)
//     setSortOpen(true)
//     setActiveButton(buttonId === 'Sort' ? 'Sort' : "Map")
//   }

//   function handleSort() {
//     // console.log("hello");
//     // setIsFilterVisible(false);
//     setSortOpen(false)
//     setIsMapVisible(false);
//     setIsListVisible(true);
//     setIsFilterVisible(false)
//     setActiveButton('List');
//     // setActiveButton(buttonId === 'Sort' ? 'Sort' : "Map")


//   }


//   const redMarkerIcon = redpin; 
//   const blueMarkerIcon = bluepin; 
//   return (
//     <div className="relative">
//       <div className=" mt-4 lg:flex">
//         {/* Map View */}
//         <div className={`lg:flex lg:w-[48%] xl:w-[65%] h-[96vh] ${isMapVisible ? "flex" : "hidden"}`}>
//           <Link to={'/'}>
//             <MdArrowBackIosNew className='absolute w-8 h-8 top-2 left-1 z-40 lg:hidden md:flex' />
//           </Link>
//           {isLoaded && mapCenter && (
//             <GoogleMap
//               center={mapCenter}
//               zoom={13}

//               mapContainerStyle={{ height: '100%', width: '100%' }}
//             >
//               {markers.map((marker, index) => {
//                 const isSelected = selectedMarkerIndex === index;
//                 const markerIcon = isSelected ? blueMarkerIcon : redMarkerIcon;

//                 return (
//                   <Marker
//                     title={marker.price}
//                     key={index}

//                     icon={{
//                       url: markerIcon,
//                       scaledSize: new google.maps.Size(40, 40), // Adjust size here (width, height)
//                     }}
//                     label={{
//                       text: `₹${formatPrice(marker.price)}`,
//                       color: '#000',
//                       fontSize: '9px',
//                       fontWeight: 'bold',
//                     }}
//                     position={{ lat: marker.latitude, lng: marker.longitude }}
//                     onClick={() => handleMarkerClick(index)} // Handle click
//                   />
//                 );
//               })}
//               {boundingPolygon.length > 0 && (
//                 <Polygon
//                   paths={boundingPolygon}
//                   options={{
//                     fillColor: 'lightblue',
//                     fillOpacity: 0.5,
//                     strokeColor: 'blue',
//                     strokeOpacity: 1,
//                     strokeWeight: 2,
//                   }}
//                 />
//               )}

//               {/* {selectedMarkerIndex !== null && (
//               <div className='w-[50px] h-[50px]'>  <InfoWindow
//                   position={{
//                     lat: markers[selectedMarkerIndex].latitude,
//                     lng: markers[selectedMarkerIndex].longitude,
//                   }}
                  
//                   onCloseClick={() => setSelectedMarkerIndex(null)}
//                 >
//                   <div
//                     className={`p-2 flex gap-2.5 transition-height duration-300 ${selectedMarkerIndex === null ? 'lg:h-[35px]' : 'h-[auto]'
//                       }`}
//                   >
//                     <div>
//                       {markers[selectedMarkerIndex]?.image && (
//                         <img className='lg:w-[180px] lg:h-[100%] rounded-md'
//                           src={markers[selectedMarkerIndex].image}
//                           alt="Property"

//                         />
//                       )}
//                     </div>
//                     <div className="">
//                       <h4 className="font-bold text-[#252B5C]  text-lg">
//                         {markers[selectedMarkerIndex]?.title}
//                       </h4>
//                       <p>{markers[selectedMarkerIndex]?.district}</p>
//                       <p>Price: {markers[selectedMarkerIndex]?.price}</p>
//                       <p>{markers[selectedMarkerIndex]?.builtup}</p>
//                     </div>
//                   </div>
//                 </InfoWindow></div> 
//               )}
//            */}
//             </GoogleMap>
//           )}


//         </div>

//         {/* List View */}
//         <div
//           className={`xl:w-[55%] lg:w-[55%] lg:grid ${isListVisible ? 'msm:grid sm:grid' : 'hidden'
//             } `}
//         >
//           {React.Children.map(children, (child, index) =>
//             React.cloneElement(child as React.ReactElement, {
//               isSelected: index === selectedMarkerIndex,
//               onClick: () => handleMarkerClick(index),
//             })
//           )}
//         </div>

//         <div className={`lg:hidden ${isFilterVisible ? 'msm:grid sm:grid overflow-y-scroll' : 'hidden'}`}>
//           <Filter kb={kb} setLoading={setLoading} toggleFilterVisibility={toggleFilterVisibility} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
//         </div>
//         <div className={` lg:hidden ${sortOpen ? 'msm:grid sm:grid' : 'hidden'}`}>
//           <SortBy setValue={setValue} handleSort={handleSort} />
//         </div>

//       </div>

//       {/* Buttons */}
//       <div className="bg-transparent flex lg:hidden w-full z-60 fixed bottom-5 justify-center items-center">
//         <div className="bg-[#F5F4F8] h-16 w-[95%] rounded-full border border-[#D6D3D1] flex justify-between items-center px-4">
//           <div className="flex items-center border-r border-[#D6D3D1] flex-1 justify-center">
//             <div className={`lg:hidden   rounded-2xl `} style={{ display: "flex", flexDirection: "row" }} onClick={() => togglefilterVisibility('Filter')}>
//               <BiFilterAlt className='h-8 w-8 ml-5' />
//               <button className='text-lg  font-semibold'


//               >
//                 Filter
//               </button>
//             </div>
//           </div>
//           <div className="flex items-center border-r border-[#D6D3D1] flex-1 justify-center">
//             <button onClick={toggle} >
//               {isOn ? <div className="lg:hidden    rounded-2xl " style={{ display: "flex", flexDirection: "row" }} onClick={() => toggleListVisibility('List')}>
//                 {/* <div className={` ${activeButton === "Map" ? 'hidden' : "flex"} border-0.5 border-l border-[#80848E] h-full `}></div> */}
//                 <CiCircleList className='h-8 w-8 ml-8' />
//                 <button className='text-lg  font-semibold'


//                 >
//                   List
//                 </button>
//               </div> : <div className="lg:hidden    rounded-2xl " style={{ display: "flex", flexDirection: "row" }} onClick={() => toggleMapVisibility('Map')}>

//                 <TfiMapAlt className='h-8 w-8 ml-8' />
//                 <button className='text-lg  font-semibold'


//                 >
//                   Map
//                 </button>
//               </div>}
//             </button>
//           </div>
//           <div className="flex items-center flex-1 justify-center">
//             <div className={`lg:hidden  rounded-2xl `} style={{ display: "flex", flexDirection: "row" }} onClick={() => toggleSortVisibility('Sort')}>

//               <BsSortUp className='h-8 w-8 ml-3' />
//               <button className='text-lg  font-semibold'


//               >
//                 Sort By
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Map;


import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  GoogleMap,
  Marker,
  Circle,
  useJsApiLoader,
} from '@react-google-maps/api';
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from 'react-router-dom';
import redpin from "../compounts/images/pinred.png"
import bluepin from "../compounts/images/pinblue2.png"
import { formatPrice } from '../compounts/ReuseableCompounts/formatepricemap';

interface MapProps {
  children: React.ReactNode;
  setValue: any;
  setSortOpen: any;
  sortOpen: any;
  setSelectedPostId: (postId: number) => void;
  value: number;
}

interface MarkerData {
  title: string;
  latitude: number;
  longitude: number;
  image?: string;
  builtup: string;
  price: string;
  district: string;
  postid: number;
}

const Map: React.FC<MapProps> = ({ children, setValue, sortOpen, setSortOpen, setSelectedPostId, value }) => {
  const [isMapVisible, setIsMapVisible] = useState<boolean>(true);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<string>('Map');
  const [selectedPostId2, setSelectedPostId2] = useState<number | null>(null);
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral | undefined>(undefined);
  const [circleCenter, setCircleCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const [circleRadius, setCircleRadius] = useState<number>(0); // radius in meters
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(null);

  const users = useSelector((state: any) => state.usersInfo.users);
  const apiKey = 'AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE';
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: apiKey });

  useEffect(() => {
    if (users.length > 0) {
      const newMarkers: MarkerData[] = users.map((user: any) => ({
        title: `${user.bhk ? `${user.bhk} ` : ''}${user.property_type}`,
        latitude: parseFloat(user.latitude),
        longitude: parseFloat(user.longitude),
        image: user.images?.find((img: any) => img.isCover)?.uri,
        builtup: `${user.built_up_area} | ${user.PriceperSqft ? `${user.PriceperSqft}/sqft` : ''}`,
        price: user.cost || user.price|| user.enter_lease_amount || user.lease_amount||user.monthly_rent||"",
        district: `${user.sublocality}, ${user.district}`,
        postid: `${user.post_id}`,
      }));

      setMarkers(newMarkers);

      // Calculate the bounding box (min/max latitudes and longitudes)
      const latitudes = newMarkers.map(marker => marker.latitude);
      const longitudes = newMarkers.map(marker => marker.longitude);

      const minLat = Math.min(...latitudes);
      const maxLat = Math.max(...latitudes);
      const minLng = Math.min(...longitudes);
      const maxLng = Math.max(...longitudes);

      // Calculate the center of the circle (the midpoint of the bounding box)
      const centerLat = (minLat + maxLat) / 2;
      const centerLng = (minLng + maxLng) / 2;

      setMapCenter({ lat: centerLat, lng: centerLng });
      setCircleCenter({ lat: centerLat, lng: centerLng });

      // Calculate the radius as the distance from the center to the farthest point
      const R = 6371; // Radius of Earth in kilometers
      const lat1 = centerLat * Math.PI / 180;
      const lng1 = centerLng * Math.PI / 180;

      // Calculate the distance to the farthest point (in this case, we will use the max distance between the center and the markers)
      let maxDistance = 0;
      for (let i = 0; i < newMarkers.length; i++) {
        const lat2 = newMarkers[i].latitude * Math.PI / 180;
        const lng2 = newMarkers[i].longitude * Math.PI / 180;
        const dLat = lat2 - lat1;
        const dLng = lng2 - lng1;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(lat1) * Math.cos(lat2) *
                  Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c * 1000; // distance in meters
        maxDistance = Math.max(maxDistance, distance);
      }

      setCircleRadius(maxDistance); // set the radius of the circle
    } else {
      setMapCenter({ lat: 11.1271, lng: 78.6569 });
      setMarkers([]);
      setCircleCenter(null);
      setCircleRadius(0);
    }
  }, [users]);

  const handleMarkerClick = (index: number) => {
    setSelectedMarkerIndex(index);
    setMapCenter({
      lat: markers[index].latitude,
      lng: markers[index].longitude,
    });
    setSelectedPostId(markers[index].postid);
    setSelectedPostId2(markers[index].postid);
  };

  const redMarkerIcon = redpin; 
  const blueMarkerIcon = bluepin;

  return (
    <div className="relative">
      <div className="mt-4 lg:flex">
        {/* Map View */}
        <div className={`lg:flex lg:w-[48%] xl:w-[65%] mt-7 h-[93vh] ${isMapVisible ? "flex" : "hidden"}`}>
          <Link to={'/'}>
            <MdArrowBackIosNew className='absolute w-8 h-8 top-2 left-1 z-40 lg:hidden md:flex' />
          </Link>
          {isLoaded && mapCenter && (
            <GoogleMap
              center={mapCenter}
              zoom={13}
              mapContainerStyle={{ height: '100%', width: '100%' }}
            >
              {markers.map((marker, index) => {
                const isSelected = selectedMarkerIndex === index;
                const markerIcon = isSelected ? blueMarkerIcon : redMarkerIcon;
                const markerSize = isSelected ? new google.maps.Size(40, 40) : new google.maps.Size(30, 35);  // Different size based on selection
                const fontSize = isSelected ? "12px" : "10px";  // Different size based on selection

                return (
                  <Marker
                    title={marker.price}
                    key={index}
                    icon={{
                      url: markerIcon,
                      scaledSize: markerSize, // Set size based on selection
                    }}
                    label={{
                      text: `₹${formatPrice(marker.price)}`,
                      color: '#fff',
                      fontSize: fontSize,
                      
                      fontWeight: 'bold',
                    }}
                    
                    position={{ lat: marker.latitude, lng: marker.longitude }}
                    onClick={() => handleMarkerClick(index)} // Handle click
                  />
                );
              })}
              
              {/* Circle around the markers */}
              {circleCenter && circleRadius > 0 && (
                <Circle
                  center={circleCenter}
                  radius={circleRadius}
                  options={{
                    fillColor: 'lightblue',
                    fillOpacity: 0.3,
                    strokeColor: 'blue',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                  }}
                />
              )}
            </GoogleMap>
          )}
        </div>

        {/* List View */}
        <div
          className={`xl:w-[55%] lg:w-[55%] lg:grid ${isListVisible ? 'msm:grid sm:grid' : 'hidden'}`}
        >
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child as React.ReactElement, {
              isSelected: index === selectedMarkerIndex,
              onClick: () => handleMarkerClick(index),
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Map;
