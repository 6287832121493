import React, { useEffect, useState } from "react";
import BG1 from './images/BG1.png';
import { HiBell } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import sort from './images/sortby.svg';
import filter from './images/filter.svg';
import { ImInsertTemplate } from "react-icons/im";
import cancel from './images/cancel.svg';
import Filter from "./filter/Filter";
import SortBy from "./sortBy/SortBy";
import search from "./images/Search.svg"
import { Chip, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'; 
import zIndex from "@mui/material/styles/zIndex";

interface SearchItem {
    id: number;
    place: string;
}

interface sort {
    sortOpen: any
    setValue: any
    setSortOpen: any
    setLoading: any
    setfilterLoaction: any
    filterLoaction: any
}

const Search: React.FC<sort> = ({ sortOpen, setValue, setSortOpen, setLoading, setfilterLoaction, filterLoaction }) => {
    const [ename, setEname] = useState<string>("");
    const [sitem, setSitem] = useState<SearchItem[]>([
        { id: 1, place: "Adyar" },
        { id: 2, place: "Tambaram" }
    ]);
    const [filterProperty, setFilterProperty] = useState<string[]>([]);
    // const [filterLoaction, setfilterLoaction] = useState<any[]>([])
    const [filterPrice, setFilterPrice] = useState<any>(true)
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
    const [kb, setKb] = useState<any>(false)
    const [receivedValue, setReceivedValue] = useState<any>(null);
    console.log(filterLoaction);
    console.log(filterProperty);
    console.log(filterPrice);




    const handgleinput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEname(event.target.value);
    }

    const addEname = () => {
        if (ename.trim() !== "") {
            setSitem([...sitem, { id: sitem.length + 1, place: ename }]);
        }
        setEname("");
    }

    const handgleinputdelete = (id: number) => {
        setSitem(sitem.filter(item => item.id !== id));
    }

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
        setSortOpen(false)

    }
 

    function handleSort() {
        console.log("hello");
        setIsFilterVisible(false);
        setSortOpen(!sortOpen)
    }

       // Handle delete logic for filter location
       const handleDeleteLocation = (location: string) => {
        setfilterLoaction((prevLocations: any[]) => prevLocations.filter(item => item !== location));
    };
    return (
        <div className=" md:hidden lg:block md:h-0 lg:h-[99%] h-[99%] " >
            <div className=" lg:block bg-[#FFFFFF] lg:mt-[4%] msm:hidden sm:hidden">

                    <div className="flex flex-row items-center pl-2 " style={{width:"100%"}}>
                     
                
                  <Box className="flex ml-3  items-center  " sx={{ width: '62%',}}>
      <Box className="lg:w-[440px] xl:w-[440px] custom-xl:w-[550px] ">
        <p className="font-semibold">You are currently searching a property at</p>
        <Box className="flex gap-2 mt-1 mb-1 overflow-y-hidden custom-scrollbar ">
          {Array.isArray(filterLoaction) && filterLoaction.map((item, index) => (
            <Chip
              key={index}
              label={item}
              onDelete={() => handleDeleteLocation(item)}  // Call the delete handler
              deleteIcon={<CancelIcon />}
              sx={{ backgroundColor: '#D1D1D1', color: '#252B5C' }} // Customize the Chip styles
            />
          ))}
        </Box>
      </Box>
    </Box>
    <div className="border-solid border-1  border-l border-gray-600 lg:h-9  mx-3"></div>

    <div style={{ width: "30%" }} className="flex justify-between ">
    <div 
        onClick={handleSort} 
        className="cursor-pointer flex items-center text-[#1063b5] gap-1 w-[50%]
            hover:text-[#0cb7d1] transition duration-200 
            active:scale-95  active:shadow-md"
    >
        <img className="flex justify-center items-center h-4.5" src={sort} alt="Sort" />
        <p className="flex justify-center items-center lg:text-base text-lg font-semibold">Sort by</p>
    </div>
    <div className="border-solid border-1  border-l border-gray-600 lg:h-9  mx-3  mt-1"></div>

    <div 
        onClick={toggleFilterVisibility} 
        className="cursor-pointer flex items-center gap-1  w-[50%]
           transition duration-200 
            active:scale-95 active:shadow-md"
    >
        <img className="h-4.5" src={filter} alt="Filter" />
        <p className="font-semibold xl:text-lg lg:text-base text-[#1063b5]   hover:text-[#0CB7D1]">Filter</p>
    </div>
</div>


                </div>
           
            </div>

            <div className={`transition-all duration-1000 ${isFilterVisible ? 'flex' : 'hidden'}`}  style={{zIndex:999}}>
                <Filter kb={kb} setLoading={setLoading} toggleFilterVisibility={toggleFilterVisibility} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
            </div>

            <div className={`${sortOpen ? "flex" : "hidden"} `}
            >
                <SortBy setValue={setValue} handleSort={handleSort} />
            </div>
        </div>
    );
}

export default Search;
