import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { AccountCircle, } from "@mui/icons-material";
import vattaraimg from "../../compounts/images/vattaraLogo.png";
import vattaraimg2 from "../../compounts/images/vattaraLogo2.png";

import chat from '../../assets/chat.png';
import bell from '../../assets/bell.png'
import MNavbar from './MNavber';
import bell1 from '../images/bellmsm.svg'
import vattara from '../images/vattaralogomsm.svg'
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { useStepperContext } from '../PostForm/component/StepperContext';
import { resetUserLoginData } from '../../slices/userDataSlice';
import { resetUser } from '../../slices/filterSlice';
import { axiosInstance } from '../api/APIs';
import { fontSize } from '@mui/system';
import { Link } from 'react-router-dom';


const SOCKET_URL = 'https://vattaram.in';



const NavbarMain: React.FC = () => {
  const dispatch=useDispatch()
  const {resetForm}=useStepperContext()
  const loginData = useSelector((state: any) => state.userData.userLoginData)
  const [Data,setData]=useState<any>([])
const navigate = useNavigate()

  useEffect(()=>{
    const socket = io(SOCKET_URL);
    fetch()

    socket.on('connect', () => {
        console.log('connected');
      });
  
      // Handle incoming 'userDetails' event
      socket.on('userDetails', (data) => {
        const deviceExists = data.logged_device.some((device:any) => device.id === loginData.Id);
        console.log("exists",deviceExists)
        if (data.userId ===loginData?.user_id && !deviceExists) {
            dispatch(resetUserLoginData())
            dispatch(resetUser())
           // navigate('/')
            resetForm()
        }
      });
  
      // Clean up the socket connection on component unmount
      return () => {
        socket.off('connect');
        socket.off('userDetails');
        socket.disconnect();
      };
},[loginData])

const fetch = async () => {
    try {
        if (loginData?.user_id) {  // Ensure loginData and user_id exist before making the request
            const response = await axiosInstance(`/user/login/deviceGet/${loginData.user_id}`);
           
            if(response.status===200){
                setData(response.data.Data)
            }
        }
    } catch (error) {
        console.error("Error fetching device history:", error);
    }
};





  const Navigate = useNavigate()
  const state=useSelector((state)=>state)

  console.log("mysatte",state)


  const handleProfile = () => {
    if (loginData.phone !== "" && loginData.name !=="" && loginData.email !=="") {
      Navigate('/profile')
    }
    else {
      Navigate('/contact',{state:{post:false}})
    }
  }


  const handlePost = () => {

    if (loginData.phone == "" && loginData.name =="" && loginData.email =="" ) {
      Navigate('/loginPost',{state:{post:true}})
    }
    else if (loginData.category === "" || loginData.category === null) {
      Navigate('/loginPostList')
    }
    else if (loginData.name !== "" && loginData.category !== "" && loginData.category !== null) {
     Navigate("/PostForm");

    }


  }

  const gradientStyle = {
    background: 'linear-gradient(to right, #9C55D3, #2D6B8E)',
    fontSize:"10px"

  };

  function handleservices() {
    Navigate('/services')
  }
  function handleHome() {
    Navigate('/')
  }

  const handleChatClick = () => {


    if (loginData.phone == "" || loginData.name =="" || loginData.email =="") {
      Navigate('/contact')
    }
    else {
      Navigate('/chatscreen');
    }

  };



  return (
    <div className=''>
      <div style={{display:"flex",alignItems:"center",    zIndex:999, boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"}} className={`sm:fixed msm:fixed top-0 w-full  msm:bg-[#FFFFFF] sm:bg-[#FFFFFF] sm:block lg:block border-b-slate-400 lg:py-2 msm:py-1  `}>
        <ul style={{justifyContent:"space-between",display:"flex",width:"100%"}} className={` msm:p-0 lg:px-4 sm:px-2 sm:py-0 lg:py-0 xl:py-0 md:py-0  `}>
          <li  className="flex items-center">
          <Link to="/"> {/* Directly using '/' for home navigation */}
          <img src={vattaraimg2} className="w-30 h-8 msm:hidden sm:flex md:flex" alt="Vattara Image" />
            <img src={vattaraimg2} className='sm:hidden msm:flex w-30 h-7' />
            </Link>
            {/* <span className="ml-2 sm:font-bold lg:text-3xl md:text-xl  msm:hidden sm:flex  sm:text-white">Vattara</span> */}
          </li>

          <li className="flex  items-center  sm:p-0 msm:p-2">
           <div onClick={handlePost} style={{fontSize:"15px",fontWeight:"bold"}} className='msm:bg-[#1063b5] py-1 cursor-pointer sm:text-black  sm:bg-[#F5F4F8] msm:text-white rounded-[10px] px-4 mr-5 msm:flex md:hidden sm:flex '> Post Ad</div>
            <button onClick={handlePost}style={{fontSize:"17px",borderRadius:"8px",fontWeight:500,}} className="text-[#fff] cursor-pointer msm:hidden sm:hidden md:flex   px-3 py-1 bg-[#1063b5]  mr-5">
              Post Free Property Ad 
            </button >
            <div className='border-1 border-black h-7  msm:hidden sm:flex opacity-30  sm:mr-1'></div>

            <div onClick={handleservices} className=" cursor-pointer sm:flex msm:hidden  items-center relative sm:px-5 lg:px-5 ">

             <div className=" font-medium text-lg  text-[#000]  ">
                Services
              </div>
             <div  style={{
    ...gradientStyle, 
    marginTop: '-15px',
    padding: '0.25rem 0.5rem',
    borderRadius: '1.5rem',
    color: 'white', 
  }}  >NEW</div>
            </div>
            <div className='border-1 border-black h-7  msm:hidden sm:flex opacity-30  sm:mr-1'></div>


           
            <div onClick={handleChatClick}
              className=' gap-2 items-center msm:hidden sm:flex  sm:px-5 lg:px-5 '>
              <img src={chat}  className='w-6 h-6 '/> 
             <div className='text-black cursor-pointer text-lg lg:flex sm:hidden'>Messages</div>
            </div>
            <div className='border-1 border-black h-7  msm:hidden sm:flex opacity-30  sm:mr-1'></div>

            <div onClick={handleProfile} className='cursor-pointer sm:flex msm:hidden justify-center items-center gap-2'>

              <AccountCircle className="text-[#1063b5] text-lg" />
              <span className="text-black capitalize text-lg font-medium">
  {loginData.name && loginData.name.length > 0
    ? `${loginData.name.slice(0, 20)}${loginData.name.length > 20 ? '...' : ''}`
    : "Login"}
</span>

            </div>


          </li>
        </ul>
        {/* <div className='border-1 border-[#F5F5F5] shadow-xl msm:flex sm:hidden'></div> */}
      </div>

    </div>
  )
}

export default NavbarMain