import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import profilePic from "../../assets/user.png";
import edit from "../../assets/Edit.png";
import companydetailicon from "../../assets/companydetailsicon.png";
import solarupdown from "../images/listingIcon.svg";
import duotone from "../images/savedIcon.svg";
import language from "../../assets/languageicon.png";
import logout from "../../assets/Logout.png";
import { useNavigate } from "react-router-dom";
import question from "../images/quesIcon.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import contactsupport from "../images/ContactSupport.svg";
import settings from "../../assets/Settings.png";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Box, IconButton, Modal } from "@mui/material";
import Language from "./Language";
import CloseIcon from "@mui/icons-material/Close";
import commercial from "../images/PlanIcon2.svg";
import resedentail from "../images/PlanIcon1.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { resetUserLoginData } from "../../slices/userDataSlice";
import { resetUser } from "../../slices/filterSlice";
import { useStepperContext } from "../PostForm/component/StepperContext";
import MNavbar from "./MNavber";
import defaultaime from "../../../src/images/defaultaime.png"
import Footer from "../Footer/Footer";



const Profile: React.FC = () => {
  const [showmodal, setshowmodal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const loginData = useSelector((state: any) => (state.userData.userLoginData))
  const navigate = useNavigate();
  console.log(loginData);
  const dispatch = useDispatch()
  const { resetForm } = useStepperContext()

  const handleChange = () => {

    navigate("/edit");
  };
  const handleClick = () => {
    navigate("/list");
  };

  const handleClick1 = () => {
    navigate("/cart");
  };

  const handlesetting = () => {
    navigate("/settings");
  };

  const handleopenmodel = () => {
    setshowmodal(true);
  };
  const handleCloseModal = () => {
    setshowmodal(false);
  };


  const handleLogout = async () => {
    const body = {
      logout_device_id: loginData.Id,
      new_login_deviceName: {},
      mobile_no: loginData.phone,
      Logout: true,
    }
    try {
      const response = await axiosInstance.post("/user/login/removeDevice", body);
      if (response.status === 200) {
        dispatch(resetUserLoginData())
        dispatch(resetUser())
        resetForm()
        navigate("/")
      }
    } catch (error) {
      console.error('Error fetching data:', error);

    }
  }
  const handleOpenLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };
  return (
    <div className=" bg-[#F5F4F8]  flex flex-col w-full sm:pt-7 md:pt-10 lg:mt-7 xl:mt-7 msm:mt-0 lg:pt-8 xl:pt-8 msm:pt-1  ">

<div className="w-full justify-center flex items-center">
      <div className={` bg-white xl:mx-[24%]  lg:mx-[20%] sm:mx-[1%] msm:mx-[1%] msm:pt-1 sm:pt-4 lg:pt-10 xl:pt-10 md:mt-0 lg:mt-0 sm:mt-5 msm:mt-0  w-[70%] msm:w-[99%] sm:w-[99%] flex flex-col rounded-2xl mb-10 `}>
      <div className="     justify-center    rounded-lg    ">
      <div className="ml-3 text-[18px] font-bold mb-2 block md:hidden">
    Profile
</div>

          <div className="flex items-center   bg-gradient-to-r ml-14 from-gray-100 to-[#C0C3DD]   p-2  rounded-l-full sl:ml-3">
            <div className="flex flex-row items-center gap-4 w-[93%] ">
              <div className="sm:w-[17%] msm:w-[27%]  lg:w-[17%] ">
                <img
                  src={profilePic}
                  alt="Profile"
                  className="rounded-full"
                />
              </div>
              <div className=" gap-1 flex flex-col sm:w-[70%] msm:w-[70%]  lg:w-[80%] ">

                {loginData?.name !== null && loginData.name !== undefined && loginData.name !== "" ? (
                  <div className="w-94 whitespace-nowrap overflow-hidden text-ellipsis text-left lg:text-[20px] msm:text-[15px] sm:text-[15px] font-bold">
    {loginData.name}</div>
                ) : (
                  <p className="font-medium">Guest_84562</p>
                )}
                {loginData?.category ? (<p className="text-[20px] msm:text-[15px] sm:text-[15px] font-bold " style={{color:"#006CE7"}}>{loginData.category}</p>) : ("")}
                {loginData?.phone ? (<p className="text-[20px] msm:text-[12px] sm:text-[15px]">{loginData.phone}</p>) : (<p className="text-xs text-[#80848E]"></p>)}
                {loginData?.email ? (<p className="text-[20px] msm:text-[12px] sm:text-[15px]">{loginData.email}</p>) : (<p className="text-xs text-[#80848E]"></p>)}







              </div>
            </div>
            <div className="ml-auto">
              <img
                onClick={handleChange}
                src={edit}
                alt="Edit"
                className="w-7 h-7 cursor-pointer mr-5"
              />
            </div>
          </div>
          <div className="lg:mx-14">
            {/* <div className="border mt-4"></div> */}
            <div className={`${loginData.company_name ? "block" : "hidden"} mt-4 px-3`}>
              <p className="font-medium">Company details</p>
              <div className="flex items-center mt-2">
                <img
                  src={loginData.company_logo || defaultaime}
                  alt="Company Icon"
                  className="w-12 h-12 mr-2 rounded-full"
                />
                {loginData.company_name !== "" && <p className="text-[20px] msm:text-[13px] sm:text-[15px] font-bold ">{loginData.company_name}</p>}
              </div>
            </div>
            <div className="border mt-3"></div>


            <div className="flex lg:gap-4 sm:gap-4 msm:gap-2 mb-3 msm:mt-2 sm:mt-4 lg:mt-4 xl:mt-4 lg:px-3 msm:px-1">
              <div onClick={handleClick} className="flex items-center gap-2 cursor-pointer bg-[#F5F4F8] rounded-2xl py-3 px-6">
                <img
                  src={solarupdown}
                  alt="Listings Icon"
                  className=""
                />

                <div

                  className="flex flex-col justify-center"
                >
                  <p className="text-gray-800 text-[20px] msm:text-[16px] sm:text-[15px] font-bold ">Listings</p>
                  <p className="text-gray-800 font-semibold text-[15px] msm:text-[10px] sm:text-[15px]">My Active Listings</p>
                </div>
              </div>
              <div onClick={handleClick1} className="flex items-center cursor-pointer gap-2  bg-[#F5F4F8] rounded-2xl py-3 px-6">
                <img src={duotone} alt="Saved Icon" />
                <div

                  className="flex flex-col justify-center"
                >
                  <p className="text-gray-800 text-[20px] msm:text-[16px] sm:text-[15px] font-bold ">Saved</p>
                  <p className="text-gray-800 font-semibold text-[15px] msm:text-[10px] sm:text-[15px]">My Favourite Properties</p>
                </div>
              </div>
            </div>
            <div className="border msm:mt-2 sm:mt-4 lg:mt-4"></div>

            <div className="">
              <p className="ml-5 mt-1 text-[#1063b5] text-lg  font-bold">
                Choose Your Subscription Plan
              </p>

              <div className="flex flex-row lg:gap-4  msm:gap-1 mt-2 lg:px-3">
                <Link to={'/Subscription'}> <div className="flex flex-row msm:gap-1 lg:gap-0 items-center bg-[#E4E0EE] msm:px-4 sm:px-6 lg:px-7 msm:py-3 lg:p-3 rounded-full">
                  <img
                    src={resedentail}
                    className="w-6 h-6 lg:mr-2"
                    alt="Residential Icon"
                  />
                  <p className="font-semibold msm:text-[15px] sm:text-[15px] lg:text-[17px]">Residential Plans</p>
                </div></Link>
                <Link to={'/Subscription'}>
                  <div className="flex flex-row msm:gap-1 lg:gap-0 items-center bg-[#E4E0EE] lg:p-3 msm:px-4 msm:py-3 sm:px-6 lg:px-7  rounded-full font-k2d">
                    <img
                      src={commercial}
                      className="w-6 h-6 lg:mr-2"
                      alt="Commercial Icon"
                    />
                    <p className="text-black font-semibold msm:text-[15px] sm:text-[15px] lg:text-[17px]">Commercial Plans</p>
                  </div>
                </Link>
              </div>
            </div>

            {/* <div className="border mt-4"></div>
            <div className="mt-4 px-3">
              <p className="text-[#80848E]  font-">Language</p>
              <div className="flex items-center cursor-pointer mt-2" onClick={handleopenmodel}>
                <img
                  src={language}
                  alt="Language Icon"
                  className="w-6 h-6 mr-2 sl:mt-3"
                />
                <p className="font-semibold text-gray-800 sl:mt-3">
                  Language Preference
                </p>
              </div>
              <Modal
                open={showmodal}
                onClose={handleCloseModal}
                aria-labelledby="thankyou-modal-title"
                aria-describedby="thankyou-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 300,
                    maxHeight: "50vh",
                    overflow: "auto",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    pt: 1,
                    px: 1,
                    pb: 3,
                  }}
                >
                  <Language />
                  <IconButton
                    onClick={handleCloseModal}
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      color: "#000",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </div> */}
            <div className="border mt-4"></div>

            <div className="mt-4 px-3">
              <p>Help and support</p>
              <Link to={'/helpCenter'}>
                <div className="flex cursor-pointer items-center mt-3">
                  <img src={question} alt="Help Icon" className="w-4 h-4 mr-2" />
                  <p className="font-semibold text-gray-800">Help Center</p>
                </div>
                </Link >
            </div>
            {/* <div className="border mt-4"></div> */}

            <div onClick={handlesetting} className="flex cursor-pointer items-center mt-4 px-3">
              <img src={settings} alt="Settings Icon" className="w-4 h-4 mr-2" />
              <p className="font-semibold text-gray-800" >
                Settings
              </p>
            </div>

            <div onClick={handleOpenLogoutModal} className="flex cursor-pointer items-center mt-4 mb-4 px-3">
        <img src={logout} alt="Logout Icon" className="w-4 h-4 mr-2" />
        <p className="font-medium text-red-600">Logout</p>
      </div>
             {/* Logout Confirmation Modal */}
      <Modal
        open={showLogoutModal}
        onClose={handleCloseLogoutModal}
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width:300,
            borderRadius:"10px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: 'center'
          }}
        >
<div id="logout-modal-title" className="mb-10 text-[20px] font-bold mt-2">
    Are you sure you want to <span className="text-red-500">log out</span> of your account?
</div>
          <div className="text-[10px]" >You will need to log in again to access your saved properties and messages.
            </div>
          <div className="flex justify-around mt-4 gap-2">
          <button 
  onClick={handleCloseLogoutModal} 
  className="border-1 border-red-500 text-red-500 px-4 py-2 rounded-3xl bg-white"
>
  Cancel
</button>

            <button 
              onClick={() => {
                handleLogout();
                handleCloseLogoutModal();
              }} 
              className="bg-red-500 text-white px-4 py-2 rounded-3xl"
            >
              Logout
            </button>
          </div>
          <IconButton
            onClick={handleCloseLogoutModal}
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              color: "#000",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>

            <div className="border mt-4"></div>
            <div className="my-4 px-3 ">
              <div className="flex cursor-pointer gap-2">
                <img src={contactsupport} />
                <p className="font-semibold">Contact Support</p>
              </div>
              <p className="mt-4">Call: +91 9444995995</p>
              <p className="msm:mb-32 lg:mb-0">E-mail: support@vattara.com</p>

            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer/>
      <div className={`lg:hidden sm:hidden msm:flex fixed bottom-1  z-50`}>
      <MNavbar />
      </div>
    </div>
  );
};

export default Profile;
