


import React, { useEffect, useState, useRef, ReactNode } from 'react';
import { useSelector } from "react-redux";
import { axiosInstance } from "../api/APIs";
import { truncateString } from "../ReuseableCompounts/TruncateString";
import { formatPrice } from "../ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import { formatSQPrice3 } from "../ReuseableCompounts/PriceSQFormat";

import star from '../images/star.svg';
import goldtick from '../images/goldtick.png';
import location from '../../compounts/images/location.png';
import DefaultScroll from '../../compounts/images/defaultScroll.png';
import { Box, CircularProgress } from '@mui/material'; // Import CircularProgress
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { handleChange, handleLinkClick } from '../ReuseableCompounts/PostDetail';
import { Link } from 'react-router-dom';


interface Props {
    lookingToValue: any;
    disValue: any
}

interface Image {
    id: number;
    isCover: any;
    isvisible: any;
    name: any;
    uri: any;
}

interface Rows {
    sublocality: String;
    id: number;
    post_id: number;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any;
    liked?: any;
    user_id?: any;
    district?: string;
    kbl_property: any;
}

const RecommendedProperties: React.FC<Props> = ({ lookingToValue, disValue }) => {
    const [cart, setCart] = useState<Rows[]>([]);
    const [loadingCardId, setLoadingCardId] = useState<number | null>(null); // Track loading card ID
    const loginData = useSelector((item: any) => item.userData.userLoginData);
    const scrollRef = useRef<HTMLDivElement>(null);

    // const handleChange = async (id: number, property: string, looking_to?: string) => {
    //     const body = {
    //         post_id: id,
    //         selected_property_type: property,
    //         looking_to: looking_to || ''
    //     };

    //     try {
    //         setLoadingCardId(id); // Set the loading card ID
    //         const response = await axiosInstance.post("/filter/get/postDetail", body);
    //         const queryString = new URLSearchParams({
    //             data: JSON.stringify(response.data),
    //             look: looking_to || ''
    //         }).toString();
    //         const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');
    //         if (newWindow) newWindow.focus();
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //         setLoadingCardId(null); // Reset the loading card ID
    //     }
    // };

    // const handleChange = async (id: number, property: string, looking_to?: string) => {
    //     const body = {
    //         post_id: id,
    //         selected_property_type: property,
    //         looking_to: looking_to || ''
    //     };

    //     try {
    //         setLoadingCardId(id); 
    //         const response = await axiosInstance.post("/filter/get/postDetail", body);
    //         const queryString = new URLSearchParams({
    //             data: JSON.stringify(response.data),
    //             look: looking_to || ''
    //         }).toString();

    //         // Check if the device is mobile or tablet
    //         const isMobileOrTablet = window.matchMedia("(max-width: 768px)").matches;

    //         if (isMobileOrTablet) {
    //             // For mobile and tablet, navigate to the next screen
    //             window.location.href = `/PropertyDetails?${queryString}`;
    //         } else {
    //             // For desktop, open a new window
    //             const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');
    //             if (newWindow) newWindow.focus();
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //         setLoadingCardId(null); // Reset the loading card ID
    //     }
    // };
    const scrollContainerStyle = {
        overflowX: "auto",
        whiteSpace: "nowrap",
        padding: "16px 0",
        scrollbarWidth: "none",  // Firefox
        msOverflowStyle: "none", // IE and Edge
        '&::-webkit-scrollbar': {
            display: 'none' // Chrome, Safari, and Opera
        }
    };
    useEffect(() => {
        async function handleCityFilter() {
            const url = `/filter/city/filter/Res_Sell/`;
            const body: any = {
                user_id: loginData.user_id,
                district: disValue,
                rent_or_sell: lookingToValue
            };

            try {
                const response = await axiosInstance.post(url, body);
                const data = response.data.message;
                if (Array.isArray(data)) {
                    setCart(data);
                    console.log('Expected an array but got:', data);
                } else {
                    console.error('Expected an array but got:', data);
                    setCart([]);
                }
            } catch (error) {
                console.error('Error fetching city filter data:', error);
            }
        }
        handleCityFilter();
    }, [lookingToValue, loginData.user_id, disValue]);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollRef.current) {
            const scrollAmount = direction === 'right' ? 300 : -300;
            scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    return (
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
            <div className="relative w-full sm:w-full md:w-full lg:w-[80%] xl:w-[60%] xs:w-[95%] custom-xl:w-[50%] custom-xxl:w-[45%]">
                <div className='msm:text-[15px] sm:text-xl md:text-xl font-bold text-[#202020] ml-1  msm:mb-[-10px] '>
                    Recommended Properties for {lookingToValue === "Buy" ? "Sale" : lookingToValue}
                </div>
                <div className="flex items-center relative">

                    {/* <Box sx={{ width: { sm: "100%", md: "95%", ld: "60%", xl: "60%"} }}> */}

                    <div ref={scrollRef} className="custom-scrollbar  overflow-x-auto whitespace-nowrap py-4 flex space-x-4 " style={{
                        overflow: "auto", overflowY: "hidden", cursor: "pointer",
                    }}>   <Box sx={{ display: { xs: "none", lg: "flex" } }}><button
                        onClick={() => scroll('left')}
                        aria-label="Scroll Left"
                        className='z-10'
                        style={{
                            backgroundColor: "#fff",
                            position: "absolute",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "none",
                            cursor: "pointer",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                            top: "50%",
                            transform: "translateY(-50%)"
                        }}
                    >
                        <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
                    </button></Box>
                        {cart && cart.length > 0 ? (
                            cart.map((item, index) => {
                                const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                                const formattedPrice = formatPrice(price);
                                const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                                // const locality = truncateString(item.city, 23);
                                const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                                const priceSQTV = formatSQPrice(sqtv);
                                const priceSQTV2 = formatSQPrice3(sqtv);
                                const Bview = "Sview"
                                const propertyView = {
                                    postId: item.post_id,
                                    property_type: item.property_type,
                                    looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                                    userId: loginData.user_id,
                                    Bview: Bview
                                }
                                const type = encodeURIComponent(propertyView.property_type);
                                const url = `/PropertyDetails/${propertyView.postId}/${type}/${propertyView.looking}/${propertyView.Bview}`;

                                return (
                                    <div key={index} className='inline-block bg-[#F5F4F8] p-1 rounded-[15px] w-[300px] relative' style={{
                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                                    }}>
                                        {loadingCardId === item.post_id && (
                                            <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-gray-800 z-10" style={{ borderRadius: "20px" }}>
                                                <CircularProgress color="inherit" /> {/* Loader overlay */}
                                            </div>
                                        )}
                                        <div className='relative'>
                                            <div className='absolute right-0 mr-2 top-3 text-white  px-2 rounded-2xl' style={{ background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)', fontSize: "13px" }}>
                                                {item.looking_to || item.rent_or_lease || item.property_available_for}
                                            </div>
                                            <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}>
                                            <img
                                            alt='img'
                                               // onClick={(event) => { handleLinkClick(event,url) }}
                                                // className='w-full h-[180px] flex justify-center bg-black items-center object-cover rounded-[15px]'
                                                className='w-full h-[140px] bg-[#fff]  msm:h-[120px] sm:h-[180px] md:h-[180px] lg:h-[180px] xl:h-[180px] flex justify-center object-cover items-center  rounded-[15px]'

                                                src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefaultScroll}
                                            /></Link>
                                            <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center  absolute bottom-0  p-[2px] left-0`} style={{ borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
                                                <img className="w-4 h-4" src={star} />
                                                <div className="text-white mr-1" style={{ fontSize: "8px" }}>VATTARA VERIFIED</div>
                                            </div>
                                        </div>
                                        <div className='ml-1 w-[200px] msm:w-[150px] sm:w-[200px] lg:w-[200px] xl:w-[200px]'>
                                        <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}>
                                        <div 
                                                                                        //    onClick={(event) => { handleLinkClick(event,url) }}

                                            // onClick={() => { handleChange(propertyView) }}
                                                className='flex gap-1 items-left' style={{ alignItems: "center" }} >
                                                <img src={goldtick} className='w-5 h-5' style={{ marginLeft: "-4px" }} />
                                                <div className='msm:text-[11px] sm:text-[15px] md:text-[15px] hover:text-blue-600' style={{ fontWeight: 700 }}>{item.bhk} {item.property_type === "Plot / Land" ? "Plot" : item.property_type}</div>
                                            </div></Link>
                                            <div className='flex gap-1 items-left'>
                                                <img className="h-4 w-4" src={location} style={{ marginLeft: "-3px" }} />
                                                <div className='msm:text-[11px] sm:text-[14px] md:text-[14px] capitalize overflow-hidden whitespace-nowrap text-ellipsis'>
                                                    {item.sublocality}, {item.district}</div>
                                            </div>
                                            <div className='flex items-center w-full'>
                                                <div style={{ fontSize: "15px", fontWeight: 600, width: "100px" }}>₹ {formattedPrice}</div>
                                                <div className='border-[#404040] h-6 border-l'></div>
                                                <div className='ml-1'>
                                                    <div className='flex items-center msm:text-[10px] sm:text-[12px] md:text-[12px] text-[#404040]' >
                                                        <div className='truncate'>{item.built_up_area || item.plot_area || ""}</div>
                                                        {item.built_up_area || item.plot_area ? <div className='ml-1'>sq.ft</div> : ""}
                                                    </div>
                                                    {item.PriceperSqft && (
                                                        <div className='flex items-center msm:text-[10px] sm:text-[12px] md:text-[12px] text-[#404040]'>


                                                            ₹ {priceSQTV} <span >/ sq.ft</span>


                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <Box sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <div className="text-center text-gray-500" style={{ marginLeft: "100px" }}>No listings available.</div>

                            </Box>
                        )}
                        <Box sx={{ display: { xs: "none", lg: "flex" } }}>
                            <button
                                onClick={() => scroll('right')}
                                aria-label="Scroll Right"
                                className='z-10'

                                style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "50%",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "absolute",
                                    right: "-0px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    border: "none",
                                    cursor: "pointer",
                                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                                }}
                            >
                                <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
                            </button></Box>
                    </div>
                    {/* </Box> */}
                </div>
            </div>
        </Box>
    );
}

export default RecommendedProperties;
