import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Search } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";


const Settings: React.FC = () => {
  const navigate = useNavigate();

  const handleclick = () => {
    navigate("/profile");
  };
  const termservice = () => {
    navigate("/termservice");
  };

  const handleclickfaqs = () => {
    navigate("/faqs");
  };
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-[#f5f4f8]">
        <div className="lg:w-[40%] msm:w-full sm:w-full h-full bg-slate-50 rounded-2xl lg:mt-14 mb-2 sm:mt-10 ">
          <Box
            sx={{ display: "flex", justifyContent: "flex-start" }}
            className="fixed  h-15  mx-2  justify-center items-center"
          >
            <IconButton
              onClick={() => navigate(-1)}
              aria-label="Back"
              sx={{
                borderRadius: '50%', // Optional: for a circular button
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
                },
              }}
            >
              <MdArrowBackIos className='w-8 h-7' color="#000" />
            </IconButton>
            <div className="font-bold">
Settings            </div>
          </Box>
        {/* <Link to={'/Profile'}>
          <MdArrowBackIosNew className=' w-8 h-8     lg:hidden left-1 z-40 sm:flex msm:flex   md:flex' />
        </Link> */}

        {/* <div className="flex items-center font-bold mt-3">
          <p>Home.</p>
          <p>Profile.</p>
          <p>Settings</p>

        </div> */}


<div className='mt-10  flex flex-col mx-5'>

        <div className="mt-4">
          <p className="text-[#80848E] font-medium">Application Settings</p>

          <div className="flex flex-row justify-between mt-2">
            <div className=" ">
              <p className=" font-bold">App Version</p>
              <p className="text-[#80848E] text-xs ">View Current app Version</p>
            </div>

          </div>
        </div>

        <div className="border-t border-gray-300 mt-4"></div>

        <div className="mt-4">
          <p className="font-medium text-[#80848E]">Security Settings</p>
          <Link to={'/loginhistory'}>
            <div className="flex flex-row justify-between mt-2">

              <div className=" ">
                <p className="font-bold">Login History</p>
                <p className="text-xs text-[#80848E]">View recent login activity</p>
              </div>
              <div>
                <KeyboardArrowRightIcon />
              </div>

            </div>
          </Link>
        </div>




        <div className="border-t mt-4"></div>  

        <div className="mt-4">
          <p className="font-medium text-[#80848E]">Legal and Compliance</p>

          <Link to={'/terms'}>
            <div className="flex flex-row justify-between" >
              <p className="font-bold mt-2">Terms of Service</p>
              <KeyboardArrowRightIcon className="text-black" />
            </div>
          </Link>
          <Link to={'/privacy'}>
            <div className="flex flex-row justify-between text-black no-underline mt-2">
              <p className="font-bold mt-1">Privacy Policy</p>
              <KeyboardArrowRightIcon className="text-black" />
            </div>
          </Link>
        </div>

        <div className="border-t mt-4"></div>

        <div className="mt-4">
          <p className="text-[#80848E] font-medium">Help And support</p>

          <div className="flex flex-row justify-between mt-2">
            <div>
              <Link to={'/helpCenter'}>
                <p className="font-bold">Help Center</p>
              </Link>
            </div>

          </div>

        </div>

</div>
      </div>

    </div>
  );
};

export default Settings;
