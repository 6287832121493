import React, { useEffect } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animis from './Animation - 1721114927422.json';
import image1 from '../images/login-2.png';
import image2 from '../images/log-1.png';
import CloseIcon from '@mui/icons-material/Close';

interface VerifiedProps {
    onClose: () => void;
    showVerification: any
    setShowVerification:any
}

const  Verified: React.FC<VerifiedProps> = ({ onClose, showVerification,setShowVerification }) => {
    const animi = {
        animationData: animis,
    };

    const navigate = useNavigate();

    // const handleClick = () => {
    //     navigate('/list');
    // };
    useEffect(() => {
        if (showVerification === "tick") {
            const timer = setTimeout(() => {
                setShowVerification("List"); 
                console.log("Hello tick")
            }, 1000); // 5 seconds

            return () => clearTimeout(timer); // Cleanup the timer on component unmount or state change
        }
    }, [showVerification, setShowVerification, navigate]);

    return (
        // <div className="fixed flex justify-center items-center z-10 left-0 top-0 w-full h-full overflow-auto">
        //     <div className="bg-white   border border-gray-300 border-solid msm:mx-4 md:mx-0 md:overflow-y-scroll lg:overflow-hidden msm:w-[90vw] custom-xxl:w-[70vw] md:w-[100vw] lg:w-[70vw] xl:w-[60vw] w-[70vw] md:h-[50vh] lg:h-[70vh] xl:h-[600px] rounded-[2%] flex flex-col relative sm:flex sm:flex-col sm:gap-2 sm:align-middle">
        //         <div className="fixed top-2 right-2 text-gray-500 text-2xl font-bold cursor-pointer z-20 md" onClick={onClose}>&times;</div>
        //         <div className="flex flex-row h-full p-0">
        //             <div className='relative z-10'>
        //             <div className="absolute md:-top-[12%] md:-left-20 sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#FCEAB6" }}></div>
        //             </div>



        //             <div className="flex-1 flex gap-10 md:items-start sm:items-center msm:items-center flex-col justify-center z-40 mt-[6%] md:ml-[15%]">
        //                 <h4 className=" font-extrabold ">Phone Number Verified</h4>


        //                 <div className='md:ml-10 sm:ml-0 sm:justify-center md:justify-start flex mt-3'>
        //                     <Lottie
        //                         animationData={animi.animationData}
        //                         loop={true}
        //                         autoplay={true}
        //                         style={{ width: 100, height: 100 }}
        //                     />
        //                 </div>
        //                 <div className='sm:ml-10 md:ml-0 opacity-70 '>
        //                     <span className=''>Congratulations, your Phone</span><br />
        //                     <span className=' relative right-5' >number has been Verified. You can</span><br />
        //                     <span className='' >start using the app</span>
        //                 </div>
        //             </div>

        //             <div className=" lg:flex z-30 bottom-0 right-1  absolute bottom-  sm:hidden msm:hidden  md:flex justify-around items-center gap-4">
        //                 <div className="lg:h-[19vh] md:h-[16vh] w-auto ">
        //                     <img src={image1} alt='img' className='md:hidden lg:flex' />
        //                 </div>
        //                 <div className="lg:h-[52vh] md:h-[38vh] w-auto">
        //                     <img src={image2} alt='img' />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

<Box
sx={{
  display: showVerification === "tick" ? 'flex' : 'none',
  // display:"flex",
  alignItems: 'center',
  justifyContent:"center",
  position: 'absolute',
  height:"100%",
  width:"100%",

  zIndex: 10,
}}
>
<Box
  sx={{
    bgcolor: 'white',
  //   border: '0.5px solid',
    borderColor: 'gray.300',
    borderRadius: '20px',
    mx: { xs: 4, md: 10 }, // Responsive margins
    overflowY: "hidden",
    width: { xs: '90vw', sm: '70vw', md: '100vw', lg: '70vw', xl: '60vw' },
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    position: 'relative',
    boxShadow: 3, // Add box shadow here

  }}
>
  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row',height:{xs:"400px",sm:"400px",md:"400px",lg:"400px",xl:"500px"}, }}> 
                           <Box sx={{width:"10%",display:{xs:"none",sm:"none",md:"none",lg:"flex"}}}>
      
                <div className=" z-10">
                      <div className="absolute md:-top-[10%] md:-left-[10%] lg:-left-[10%]  sm:-top-[12%] sm:-left-[12%] msm:-top-[20%] msm:-left-[20%] md:w-56 md:h-56  rounded-full" style={{ backgroundColor: "#0CB7D1" }}></div>

                  </div>
      </Box>
      <Box sx={{width:{xs:"100%",sm:"100%",md:"100%",lg:"45%"},height:"100%",justifyContent:"center",display:"flex",alignItems:"center",flexDirection:"column"}}>
      <IconButton
onClick={onClose}        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
          color: 'gray.500',
        }}
      >
        <CloseIcon />
      </IconButton>     <div className="flex-1 flex gap-10 md:items-start sm:items-center msm:items-center flex-col justify-center z-40 mt-[6%] md:ml-[15%]">
                        <h4 className=" font-extrabold ">Phone Number Verified</h4>


                        <div className='md:ml-10 sm:ml-0 sm:justify-center md:justify-start flex mt-3'>
                            <Lottie
                                animationData={animi.animationData}
                                loop={true}
                                autoplay={true}
                                style={{ width: 100, height: 100 }}
                            />
                        </div>
                        <div className='md:ml-0 opacity-70 ' style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                            <div className=''>Congratulations, your Phone</div>
                            <div className='' >number has been Verified. You can</div>
                            <div className='' >start using the app</div>
                        </div>
                    </div>
  </Box>
          <Box sx={{width:"45%",height:"100%",display:{xs:"none",sm:"none",md:"none",lg:"flex"},flexDirection:"column",justifyContent:"end",alignItems:"end"}}>
        
  <Box sx={{flexDirection:"row",display:"flex",justifyContent:"end",alignItems:"end"}}>
  <IconButton
onClick={onClose}        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'gray.500',
        }}
      >
        <CloseIcon />
      </IconButton>   <Box sx={{marginX:"20px"}} >
          <img src={image1} alt='img'  className='w-full h-full' />
                      </Box>
                      <Box sx={{marginX:"20px",marginTop:"10px"}} >
                      <img src={image2} alt='img'  className='w-full h-full' />
                      </Box> </Box>
          </Box>
  </Box>
           
    </Box>
    </Box>

    );
};

export default Verified;
