// import React, { useState } from 'react'
// import DatePicker from 'react-datepicker'
// import "react-datepicker/dist/react-datepicker.css";
// import { useStepperContext } from './StepperContext';
// // import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
// import { TextField } from '@mui/material';


// interface DatePickProps{
//   label:string;
//   inputId:number;
//   formName:string;
//   required:boolean,
//   userselectedData:string
// }

// const DatePick: React.FC<DatePickProps> = ({ label, inputId, formName,required,userselectedData,initial }) => {
//   const { addUserData } = useStepperContext();
//   const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

//   const handleDateChange = (date: Date | null) => {
//     setSelectedDate(date);

//     // Example of adding the selected date to userData
//     // if (date) {
//       const newDataArray = {
//         id: inputId,  // Assuming inputId can be used as a unique identifier
//         [label]: date.toISOString(), // Store date as ISO string or format as needed
//         formName: formName,
//         required:required
//       };

//       addUserData(newDataArray);

//   };
//   const hasError=userselectedData?.includes(label)

//   return (

//     <div>
//  <DatePicker

//         selected={selectedDate}
//         label={label}
//         defaultValue={initial}
//         inputId={inputId}
//         formName={formName}
//         onChange={handleDateChange}
//         dateFormat="dd/MM/yyyy" // Example of date format (you can change this)

//       />

//       {hasError && <div>This field is required</div>}


//     </div>
//     // <LocalizationProvider dateAdapter={AdapterDateFns}>
//     //   <DesktopDatePicker
//     //     inputFormat="MM/dd/yyyy"
//     //     label={label}
//     //     value={selectedDate}
//     //     onChange={handleDateChange}
//     //     renderInput={(params) => <TextField {...params} />}
//     //   />
//     // </LocalizationProvider>
//   );
// };

// export default DatePick;

import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useStepperContext } from './StepperContext';
import dayjs from 'dayjs'; // For date formatting
import { updateValueInArray } from '../../PostForm/component';

interface DatePickProps {
  label: string;
  inputId?: number;
  formName: string;
  required?: boolean;
  userselectedData?: any;
  initial?: Date; // Optional initial date
  setDataB?: any
  baseData?: any

}

const DatePick: React.FC<DatePickProps> = ({ label, baseData, setDataB, inputId, formName, required, userselectedData, initial }) => {
  const { addUserData } = useStepperContext();
  const initialDate = initial ? dayjs(initial).format('MMM-YYYY-DD') : '';
  const [dateValue, setDateValue] = useState<string>(initial as any);
  console.log("cal", dateValue, initial, label)
  const lookToValue = baseData.find((item:any) => item.look_to)?.look_to;
  const RESCOM = baseData.find((item:any) => item.ResrCom)?.ResrCom;


  useEffect(() => {
    if (initial) {
      setDateValue(dayjs(initial).format('YYYY-MM-DD'))

    }
  }, [initial]);

  console.log("vadate", dateValue)


  function hadlelabel() {
    if (RESCOM === "Residential" && (lookToValue ==="Rent" )) {
      return "Available From"
    } else if(RESCOM === "Residential" && lookToValue==="Sale") {
      return  "Possession Status"
    }else{
      return  "Possession Status"
    }

  }


  const labelvalue:any = hadlelabel()
  console.log("if value data",labelvalue,label)
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log("valuedate", value)



   
    // Simple date validation and formatting
    const parsedDate = dayjs(value, 'MMMM D, YYYY', true)
   const correct = parsedDate.format('MMMM D, YYYY')
    setDateValue(value);
    console.log("dataformat",dateValue,correct)
    if (parsedDate.isValid()) {
     
      const newDataArray = {
        id: inputId,
        [label]: value, // Store date as ISO string
        formName: formName,
        required: required
      };

      addUserData(newDataArray as any);
    }
   

    const updateData = updateValueInArray(baseData, labelvalue, correct)
    console.log("datepick", updateData)
    setDataB(updateData)
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div>
      <TextField
        label={label}
        value={dateValue}
        type='date'
        onChange={handleDateChange}
        required
        fullWidth
        error={hasError}
        helperText={hasError ? 'This field is required' : ''}
        variant="filled"
         size="small"
   sx={{                  borderRadius: "999px",marginTop:1,
     width:'100%',
          '& .MuiFilledInput-underline:before': {
                    borderBottom: '0px solid transparent', // Dotted underline
                  },
                  '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '0px solid transparent', // Dotted underline
                  }, }} 
   InputProps={{
     style: {
       borderRadius: "999px",
fontFamily:"K2D",            backgroundColor: "#F5F4F8",
     },}}
      />
    </div>
  );
};

export default DatePick;
