import React, { useState } from "react";
import Backgroundimg from "../assets/homeimage.png";
import { Box, CircularProgress, IconButton, TextField } from "@mui/material";
import background from "../../assets/backgroung.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import profile from "../../assets/Profile.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosInstance } from '../api/APIs';
import { useDispatch } from "react-redux";
import { setUserLoginData } from "../../slices/userDataSlice";
import AWS from 'aws-sdk';
import Resizer from 'react-image-file-resizer';
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowBackIos, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import defaultaime from "../../../src/images/defaultaime.png"




AWS.config.update({
  region: 'us-east-1',
  accessKeyId: 'AKIAXKPUZW5AH27B7EGM',
  secretAccessKey: '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',
});

const S3 = new AWS.S3();


const Edit: React.FC = () => {
  const loginData = useSelector((item: any) => item.userData.userLoginData);
  const [profileImage, setProfileImage] = useState<any>(loginData?.company_logo);
  const [name, setName] = useState<string>(loginData?.name || "");
  const [email, setEmail] = useState<string>(loginData?.email || "");
  const [phone, setPhone] = useState<any>("");
  const [category, setCategory] = useState<any>(loginData?.category || "");
  const [companyName, setCompanyName] = useState<string>(loginData?.company_name || "");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false); 

  const [nameError, setNameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [companyNameError, setCompanyNameError] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(loginData);

  const handleProfileImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true);

      // Resize the image
      Resizer.imageFileResizer(
        file,
        800, // maxWidth
        800, // maxHeight
        'JPEG', // compressFormat
        90, // quality
        0, // rotation
        (uri) => {
          uploadFileToS3('vattara-img', uri as File).then((fileUrl) => {
            setProfileImage(fileUrl);
            setLoading(false); // Hide loader after upload

          }).catch((error) => {
            console.error('Error uploading profile image:', error);
            setLoading(false); // Hide loader after upload

          });
        },
        'blob' // outputType
      );
    }
  };

  const uploadFileToS3 = async (bucketName: string, file: Blob) => {
    const fileName = `profile_${Date.now()}`;
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };
    try {
      const uploadResponse = await S3.upload(params).promise();
      return uploadResponse.Location;
    } catch (error) {
      throw new Error(`Error uploading ${fileName} to S3: ${error}`);
    }
  };
  const handleClick = () => {
    navigate("/profile");
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);

    // Basic email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(""); // Clear error if valid
    }
  };

  const handleCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
  };

  const handleUpdate = async () => {

    setNameError("");
    setEmailError("");
    setCompanyNameError("");

   
    if (!name) setNameError("Name is required");
    if (!email) setEmailError("Email is required");
    if (category !== "Owner" && !companyName) setCompanyNameError("Company Name is required");

   
    if (!name || !email || (category !== "Owner" && category !=="" && !companyName)) return;

    setLoading2(true); 

    const body = {
      user_id: loginData.user_id,
      name: name,
      email: email,
      phone: loginData.phone,
      lang: "en",
      company_logo: profileImage || '',
      company_name: category !== "Owner" ? companyName : "",
      category: category,
      token: loginData.token,
      encryptedToken: loginData.encryptedToken


    };
    console.log(body);

    const url = category !== "" ? "/user/update/userDetail" : "/user/update/userNameEmail";

    try {
      const responses = await axiosInstance.put(url, body);
      console.log(responses.data.message);
      dispatch(setUserLoginData(body));
      navigate('/profile')
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setLoading2(false); // Hide loader after the operation
    }
  };
  const isEmailValid = !emailError && email !== "";


  return (
    <div className="">

   
  <div className="w-full flex flex-col justify-center items-center ">
    
  <div className="lg:w-[40%] msm:w-full sm:w-full h-full mt-10    ">
  <Box  sx={{display:"flex",justifyContent:"flex-start"}}        className="fixed w-full h-15  z-40 bg-[#fff]  lg:mt-10  sm:mt-10 md:flex  justify-center items-center lg:pt-2  top-0"
 >

 <IconButton
      onClick={() => navigate(-1)} // Inline navigation logic
      aria-label="Back"
      sx={{
        borderRadius: '50%', // Optional: for a circular button
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
        },
      }}
    >
      <MdArrowBackIos className='w-8 h-7 ' color="#000" />
    </IconButton>
    <div className="font-bold">
      Edit Profile
    </div>
  </Box>
            <img src={background} className="w-full h-full" alt="Background" />
          </div> 
          <div className="lg:w-[40%] msm:w-full flex-col sm:w-full ">
            <form className="msm:w-full flex-col sm:w-full  msm:mx-[2%] sm:mx-[2%] lg:mx-[2%] ">
            <p className="font-semibold">Name</p>

              <div className="mb-4">
                <label className="block text-gray-700  text-sm font-bold mb-2" htmlFor="username"></label>
                <input
                  value={name}
                  onChange={handleName}
                  className="shadow appearance-none border-1 border-black w-[97%]  rounded-full py-3 px-3 text-gray-700  "

                  id="username"
                  type="text"
                  placeholder="Username"
                />
                 {nameError && <p className="text-red-500 ">{nameError}</p>}
              </div>
              <p className="font-semibold">E-mail</p>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="example@gmail.com"></label>
                <input
                  value={email}
                  onChange={handleEmail}
                  className="shadow appearance-none border-1 border-black w-[97%]   rounded-full py-3 px-3 text-gray-700  "
                  id="username"
                  type="email"
                  placeholder="example@gamil.com"
                />
                 {emailError && <p className="text-red-500 ">{emailError}</p>}
              </div>
              <p className="font-semibold">Phone Number</p>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor=""></label>
                <input
                  value={loginData?.phone || ""}
                  className="shadow appearance-none border-1 opacity-60 border-black  w-[97%] rounded-full py-3 px-3 text-gray-700  "
                  id="username"
                  type="text"
                  placeholder="+91 9893737243"
                />
              </div>
              <div className={`${loginData?.category!== "" && loginData?.category!=="Owner" ? "flex flex-col" : "hidden"}`}>
                <div className="mt-1">
                  <p className="font-semibold">Company Details</p>
                  <div className="flex flex-row gap-2 mt-2">
                    <label htmlFor="profile-image-upload" className="cursor-pointer">
                      <img src={profileImage|| defaultaime} alt="Profile" className="rounded-full w-20 h-20 cursor-pointer" />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleProfileImageUpload}
                        className="hidden"
                        id="profile-image-upload"
                      />
                    </label>
                    <p  onClick={() => {
                                    const inputElement = document.getElementById('profile-image-upload') as HTMLInputElement | null;
                                    if (inputElement) {
                                        inputElement.click();
                                    }
                                }} className="mt-8 text-[#006CE7]  font-bold">Edit Image</p>
                                          {loading && <div className="mr-32 absolute mt-4"> <CircularProgress /> </div>} {/* Show loader while uploading */}

                  </div>
                </div>
                <div className="mb-4 mt-4">
                  <p className="font-semibold">Company Name</p>
                  <label className="block text-gray-700 text-sm font-bold mb-2 ml" htmlFor=""></label>
                  <input
                    onChange={handleCompanyName}
                    value={companyName}
                    className="shadow appearance-none border-1 border-black w-[97%]  rounded-full py-3 px-3 text-gray-700  "
                    id="username"
                    type="text"
                    placeholder="example co LTD"
                  />
                  {companyNameError && <p className="text-red-500">{companyNameError}</p>}
                </div>
              </div>
            </form>
            <div className="flex justify-center items-center flex-col">
              <button
                onClick={handleUpdate}
                className="bg-[#1063b5] py-3 px-28  rounded-full text-white"
                disabled={!isEmailValid}

              >
            {loading2 ? (
            <CircularProgress size={24} color="inherit" />
        ) : (
            'Save Details'
        )}
              </button>
           
             <Link to={'/terms'}> <p className="mt-2 text-sm ">
                By clicking, you accept our{" "}
                <span className="underline text-[#1063b5]">Terms & Conditions</span>
              </p></Link>
            </div>

          </div></div>

        <div className="w-full h-full bg- p-4 rounded-lg lg:mt-7 overflow-auto">
       
        
        </div>
      </div>
  );
};

export default Edit;
