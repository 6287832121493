import React, { useState,useEffect } from 'react';
import cancel from '../images/cancel.png';
import notick from '../images/notick.png';
import tick from '../images/tick.png';
import { Select, MenuItem, FormControl } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import "../../compounts/scroll.css"
import { useDispatch } from 'react-redux';
import { setUsers } from '../../slices/filterSlice';
import { axiosInstance } from '../api/APIs';
import Axios from 'axios';
import SliderComponent from './SliderComponent';
import { filterData, districts } from '../data'
import { useSelector } from "react-redux";
import AreaSlider from './AreaSlider';
import { set } from 'date-fns';
import drop from '../images/arrow_drop_down.svg'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Alert, Box, Chip, Grow, Snackbar, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';



interface LocationType {
  id: number;
  place: string;
}

interface FormErrors {
  propertyType?: any;
  locations?: any

}
interface Filtermini {
  selectedLook: string | null;
  Sub: string | null;
}
interface FilterminiProps {
  toggleFilterVisibility: (value?: any) => void
  setFilterProperty: any;
  setfilterLoaction: any
  setFilterPrice: any
  kb: any
  handleClose: () => void; // New prop for handling close

  setLoading: any

}


const Filtermini: React.FC<FilterminiProps> = ({ kb, toggleFilterVisibility,   handleClose,   setFilterProperty, setfilterLoaction, setFilterPrice }) => {

  
  const [ltext, setltext] = useState<LocationType[]>([{ id: 1, place: "Chennai" }]);
  const [etext, setetext] = useState<string>("");
  const [sliderValue, setSliderValue] = useState<[number, number]>([0, 100000000]);
  const [areaSlider, setAreaSlider] = useState<any>([0, 500000])
  const [selectedProperties, setSelectedProperties] = useState<any[]>([]);
  // const [Location, setLocation] = useState<string>('Chennai');
  const [selectedBhk, setSelectedBhk] = useState<string[]>([]);
  const [selectedLook, setSelectedLook] = useState<any>('Buy');
  const [Apply, setApply] = useState<string[]>([]);
  const [visableDistrict, setVisableDistrict] = useState<boolean>(false);
  const [round, setRound] = useState<number>(2);
  const [filterCancel, setFilterCancel] = useState<boolean>(false);  
  const [location, setLocation] = useState<string>("");
  const [cityVisible, setCityVisible] = useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]); // New state for selected locations
  const [autocompleteValue, setAutocompleteValue] = useState<any>(null);

  const [Sub, setSub] = useState<string | null>('Buy');
  const dispatch = useDispatch();
  const [CommercialVisable, setCommercialVisable] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [receivedValue, setReceivedValue] = useState<any>([]);
  const [CStatus, setCStatus] = useState<any>(0)
  const [error, setError] = useState<string | null>(""); // Allow null type
  const [searchCity, setSearchCity] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const [facing, setFacing] = useState<any>([])
  const loginData = useSelector((item: any) => (item.userData.userLoginData))


  function handleDeleteList(Id: number) {

    var arr = ltext.filter((item) => {
      if (item.id === Id) {
        return false;
      }
      else {
        return true;
      }
    });
    setltext(arr);
  }

  function hanglest(event: React.ChangeEvent<HTMLInputElement>) {
    setetext(event.target.value);
  }

  function handleAdd() {
    if (etext.trim() !== '' && ltext.length < 3) {
      setltext([...ltext, { id: ltext.length + 1, place: etext }]);
      setetext('');
      setFormErrors({ ...formErrors, locations: '' });
    } else if (ltext.length >= 3) {
      setFormErrors({ ...formErrors, locations: 'You can only add up to 3 locations.' });
    }
  }

  function handleChangeProperty(value: string) {
    if (selectedProperties.includes(value)) {
      setSelectedProperties(selectedProperties.filter(prop => prop !== value));

    } else {
      setSelectedProperties([...selectedProperties, value]);

    }
  }

  function handleFacing(id: any, value: string) {
    if (facing.includes(value)) {
      setFacing(facing.filter((prop: any) => prop !== value));

    } else {
      setFacing([...facing, value]);

    }
    console.log("filter facing ", facing)
  }

  function handleChangeBhk(value: string) {
    if (selectedBhk.includes(value)) {
      setSelectedBhk(selectedBhk.filter(item => item !== value));
      setApply(Apply.filter(prop => prop !== value));
    }
    else {
      setSelectedBhk([...selectedBhk, value]);
      setApply([...Apply, value]);
    }
  }

  function handleChangeLook(Id: number, value: string) {
    if (value === "Commercial") {
      setCommercialVisable(true);
      setSelectedProperties([]);
    }
    
    else {
      setCommercialVisable(false);
      setSelectedProperties([]);
    }


    if (selectedLook === value) {
      setSelectedLook(null);
      setApply(Apply.filter(prop => prop !== value));
    } else {
      setSelectedLook(value);
      setApply([...Apply, value]);
    }
  }

  const handleCon = (id: any, value: any) => {
    setCStatus(id)
  }

  function handleChangeSub(Id: number, value: string) {
    if (Sub === value) {
      setSub(null);

    } else {
      setSub(value);

    }
  }

  async function handleApply() {
    setLoading(true); // Start loading


    let errors: FormErrors = {};

    if (!location) {
      errors.locations = 'Please select a city/town.';
  }

  if (ltext.length === 0) {
    errors.locations = errors.locations ? `${errors.locations} Please add at least one location.` : 'Please add at least one location.';
}

    // if (selectedProperties.length === 0) {
    //   errors.propertyType = "Please select at least one property type.";
    // }
    // if (ltext.length === 0) {
    //   errors.locations = 'Please add at least one location.';
    // }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 2000)
      setLoading(false); // Stop loading if there are errors

      return;
    }


    setFormErrors({});
    const selectedLocationString = [ ...selectedLocations.map(location => location.label)];


    const body: any = {
      district: location,
      city: selectedLocationString, 
      property_type: selectedProperties.length === 0
        ? (selectedLook === "Commercial"
          ? ["Showroom", "Office Space", "Retail Shop", "Industrial Space", "Warehouse"]
          : (selectedLook === "Buy" || selectedLook === "Rent" || selectedLook === "Lease"
            ? ["Apartment", "Plot / Land", "Villa", "Independent House"]
            : selectedProperties))
        : selectedProperties,

      looking_to: selectedLook === "Commercial" ? Sub : selectedLook,
      minAmount: sliderValue[0],
      maxAmount: sliderValue[1] === 0 ? 100000000 : `${sliderValue[1]}`,
      min_plot_area: areaSlider[0],
      max_ploat_area: areaSlider[1] === 0 ? 50000 : `${areaSlider[1]}`,
      facing: (Array.isArray(facing) && facing.length === 0) ? null : facing,
      ready_to_move: CStatus,
      user_id: loginData.user_id,
      kbl_property: kb,
    };

    if (selectedBhk.length > 0) {
      body.bhk = selectedBhk;
    }

    console.log(body);
    console.log('Request naga body sent to API:', body); // Log the body before sending

    console.log(selectedLook);
    const url = selectedLook === "Commercial" ? "/filter/commercialFilter" : "/filter/resbuyfilterData";
    console.log(body)
    // setLoading(true)
    try {
      const response = await axiosInstance.post(url, body);
      dispatch(setUsers(response.data.message));
      setLoading(false)
      console.log('Response:', response.data.message);
      setFilterProperty(body.property_type);
      setfilterLoaction(body.city);
      setFilterPrice(true);
      toggleFilterVisibility();
       
      


      console.log("filter check", body.city)


    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false); // Stop loading on error

    }
  }



  function handleDistrictVisable() {
    setVisableDistrict(!visableDistrict);
  }

  function handleRound(index: number, value: string) {
    if (round === index) {
      setRound(0);
    } else {
      setRound(index);
      setLocation(value);
      setVisableDistrict(false)
    }
  }

  function toggleFilter() {
    toggleFilterVisibility();


    // setVisableDistrict(false);
  }

  function reset() {
    setSelectedLook("Buy");
    setSelectedProperties([]);
    setSelectedLocations([]);
    setSelectedBhk([]);
    setLocation("");
    setSliderValue([0, 100000000]);
    setAreaSlider([0, 500000]);
    setFacing([]);
    setCStatus(0);
    setltext([{ id: 1, place: 'Chennai' }]); // Reset to default location if needed
    setAutocompleteValue(null);
    setError(null);
    setCommercialVisable(false); // Reset commercial visibility if necessary

    localStorage.removeItem('filterData');

  }
  const handleSliderChange = (event: Event, newValue: any | any[]) => {
    setSliderValue(newValue as [number, number]);
    console.log(newValue);

  };
  const handleSelect = async (place: any) => {
    try {
      const address = place?.value?.structured_formatting?.main_text;
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);

      const addressComponents = results[0].address_components;
      const isWithinDistrict = addressComponents.some(component =>
        component.long_name.toLowerCase() === location.toLowerCase()
      );

      if (isWithinDistrict) {
        if (selectedLocations.length < 3) {

        setSelectedLocations(prev => [
          ...prev,
          {
            label: place.value.structured_formatting.main_text,
            place_id: place.value.place_id,
          }
        ]);
        // setAutocompleteValue(place);
        setSearchCity(address);
        setError(""); // Set to empty string instead of null
        console.log('Coordinates:', { lat, lng });
      }else {
        setError("You can only select up to 3 locations."); // Show an error if max is reached
      } }
      else {
        setError('Selected locality is not within the selected district.');
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
      setError('Error fetching location details');
    }
  };
  const handleDeleteLocation = (placeId: string) => {
    setSelectedLocations(prev => prev.filter(location => location.place_id !== placeId));
  };

  const handleArea = (event: Event, newValue: any | any[]) => {
    setAreaSlider(newValue as [number, number]);
    console.log(newValue);

  };
  const handleLocationChange = (value: any) => {
    console.log("Selected city/town:", value); // Log the selected value

    setCityVisible(false);
    setLocation(value);   
    setSelectedLocations([]); // Reset selected locations when location changes
  };


  return (
    // <Box className={`lg:absolute msm::relative lg:z-40 lg:block md:w-[100%] lg:w-[35%] lg:overflow-y-scroll h-[100%] top-[0%] left-0 mt-10 bg-[white] ${filterCancel ? 'hidden' : 'block'}`}>
    <Box
    className="custom-scrollbar"

    sx={{
    //   position: { lg: 'absolute', msm: 'relative' },
    //   zIndex: { lg: 40 },
      position:"absolute",
    //   zIndex:40,
      display: { lg: 'block', xs: filterCancel ? 'none' : 'block' },
      width: { xs:"99%",md: '100%', lg: '35%' },
      overflowY: 'scroll',
      height: {md: '93%', lg: '93%' ,xs:"100%"},
      top: '0%',
      left: 0,
      // paddingBottom: {xs:"5%",sm:"3%",md:"3%",lg:"2%",xl:"2%"}, // You can adjust this value to match your design

      mt: {xs:0,sm:"4%",md:"3%",lg:"2%",xl:"2%"}, // You can adjust this value to match your design
      bgcolor: 'white',
    }}
  ><div className='bg-[#F5F4F8]'>
    <Box >
   <div className='flex justify-end w-full'>
    <CancelIcon 
                        onClick={handleClose} 
                        sx={{  cursor: 'pointer',color:"#000",width:"30px",right:10}} 
                    /></div> 
      <Box sx={{padding:1,right:0,display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}} >
<Box>
<p className='text-[#202020] text-xl font-bold'>Filter</p>

</Box>
<Box>
<Box className='cursor-pointer text-[#252B5C] font-bold' onClick={reset} sx={{backgroundColor:"#fff",borderRadius:"20px", fontSize:"13px",padding:"5px",boxShadow:1}}>
           < RefreshIcon sx={{width:"16px",height:"16px"}}/>  Reset filter
            </Box>
</Box>
    </Box></Box>
        {/* <div className='flex items-center justify-between '>
       
          <div className='flex flex-col gap-2 ml-3 mt-4'>
            <p className='text-[#202020] text-xl font-bold'>Filter</p>
            <p className='text-[#202020]'>
              You are searching in <span className='font-semibold'>{location}</span>
            </p>
          </div>
          <div className='flex flex-col cursor-pointer  gap-2  mt-4'>
            <Box className='cursor-pointer text-[#252B5C] font-bold' onClick={reset} sx={{backgroundColor:"#fff",borderRadius:"10px", fontSize:"13px"}}>
           < RefreshIcon sx={{width:"16px",height:"16px"}}/>  Reset filter
            </Box>
        
          </div>

        </div> */}
        <div className='border-1 mt-1 border-[F5F4F8] box-border shadow-2xl'></div>
      </div>
      <div className='mt-2'>
        <div>
          <p className='mb-2 lg:ml-5 msm:ml-2 text-left font-semibold'>I’m Looking To</p>
        </div>
        <div>
          <div className='msm:ml-2 lg:ml-5'>
            {filterData.map((data, index) => (
              <div className='flex flex-wrap xl:gap-10 msm:gap-2 items-center ' key={index}>
                {data.look.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => handleChangeLook(item.id, item.button)}
                    className={`lg:py-2 lg:px-6 sm:py-1 msm:py-2 msm:px-4 rounded-3xl ${selectedLook === item.button ? 'bg-[#0cb7d1] text-[#FFFFFF]' : 'bg-[#F5F4F8]'} transition-all duration-200 hover:bg-[#0cb7d1] hover:text-[#FFFFFF]`}
                  >
                    {item.button}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={`${CommercialVisable ? 'block' : 'hidden'}`}>
        <p className='mt-2 msm:ml-2 lg:ml-5 mb-2 font-semibold'>Sub Category</p>
        <div className='msm:ml-2 lg:ml-5 flex flex-wrap xl:gap-20 msm:gap-5'>
          {filterData[0].SubCategory.map((item) => (
            <div key={item.id}>
              <button
                className={`px-6 py-2 rounded-3xl ${Sub === item.button ? 'bg-[#0cb7d1] text-[#FFFFFF]' : 'bg-[#F5F4F8]'} transition-all duration-200 hover:bg-[#0cb7d1] hover:text-[#FFFFFF] `}
                onClick={() => handleChangeSub(item.id, item.button)}
              >
                {item.button}
              </button>
            </div>
          ))}
        </div>
      </div>

         <div className="mt-2 lg:ml-5 msm:mr-2 lg:mr-0 msm:ml-2">

<p className="text=lg font-semibold mb-2 ">City/Town</p>
<FormControl variant="outlined" sx={{ width:"95%", border: 'none', }}>
  <Select
    value={location}
    onChange={(e) => handleLocationChange(e.target.value)}
    displayEmpty
    inputProps={{ 'aria-label': 'Select city/town' }}
    sx={{
      backgroundColor:"#F5F4F8",
      height: '40px', // Control inner height

      borderRadius:"20px",
      '& .MuiOutlinedInput-notchedOutline': {
          border: 'none', // Remove the border
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none', // Remove border on hover
      },
  }}
  >
    <MenuItem value="" disabled>
      Choose Your Location
    </MenuItem>
    {districts.map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ))}
  </Select>
</FormControl>
{showAlert && formErrors.locations && (
    <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
        <Alert onClose={() => setShowAlert(false)} severity="error">
            {formErrors.locations}
        </Alert>
    </Snackbar>
)}
</div>
<div className="mt-2 lg:ml-5 msm:mr-2 lg:mr-0 msm:ml-2">
   
<p className="text=lg font-semibold mb-2 ">Localities</p>   
        <Box sx={{width:"95%"}}>   
         <GooglePlacesAutocomplete     
                                        apiKey={process.env.AIzaSyDz2gppwqEkyckilXFDJkvgEtcoS2OEvE}
                                        autocompletionRequest={{
                            componentRestrictions: { country: ['in'] },
                          }}
                          selectProps={{
                            styles: {
                              control: (base: any) => ({
                                ...base,
                                border: 'none',
                                borderRadius:"20px",

                                // width: 350,
                                backgroundColor:"#F5F4F8",
                                boxShadow: 'none',
                                '&:hover': { border: 'none' },
                              }),
                            },
                            value: autocompleteValue,
                            onChange: handleSelect,
                            placeholder: 'Search for localities...',
                            isClearable: true,
                            formatOptionLabel: (option: any) => option.label || option.description // Show the main_text in the select
                          }}
                          debounce={500}
                        /></Box>
      </div>
      {error && (
                          <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError("")}>
                            <Alert onClose={() => setError("")} severity="error">{error}</Alert>
                          </Snackbar>
                        )}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 1 }}>
                      {selectedLocations.map((location) => (
                        <Chip
                          key={location.place_id}
                          label={location.label}
                          onDelete={() => handleDeleteLocation(location.place_id)}
                          sx={{ margin: '4px' }}
                        />
                      ))}
                    </Box>
     

      <div>
        <p className='font-semibold lg:ml-5  msm:ml-2  mt-2 text-left'>Property Type</p>
        <div className={` gap-1 lg:p-2 msm:p-1 ${CommercialVisable ? 'hidden' : 'xl:grid xl:grid-cols-3 msm:grid msm:grid-cols-2 lg:ml-3  msm:ml-2  '} `}>
          {filterData[0].property.map((item, index) => (
            <div
              key={index}
              style={{display:"flex",alignItems:"center",flexDirection:"row"}}
              onClick={() => handleChangeProperty(item.value)}
              className={`flex cursor-pointer rounded-3xl lg:text-base msm:text-x ${selectedLook !== "Buy" && item.value === "Plot / Land" ? "invisible" : "block"} ${selectedProperties.includes(item.value) ? 'bg-[#0cb7d1] text-[#FFFFFF]' : 'bg-[#F5F4F8]'} xl:p-0 lg:px-1 msm:py-2 lg:py-1 transition-all duration-200 hover:bg-[#0cb7d1] hover:text-[#FFFFFF]`}
              >
              <img className='ml-1   w-5 h-5' src={selectedProperties.includes(item.value) ? tick : notick} alt='tick or notick' />
              <p className={`p-1 `} style={{fontSize:"13px"}}>{item.value}</p>

            </div>
          ))}
        </div>  

        <div className={` gap-3 p-2  ${CommercialVisable ? 'xl:grid xl:grid-cols-3  msm:grid msm:grid-cols-2 lg:ml-3  msm:ml-2 ' : 'hidden'}`}>
          {filterData[0].propertyCommercial.map((item, index) => (
            <div
              key={index}
              onClick={() => handleChangeProperty(item.value)}
              className={`flex cursor-pointer items-center rounded-3xl ${selectedProperties.includes(item.value) ? 'bg-[#0cb7d1] text-[#FFFFFF]' : 'bg-[#F5F4F8] xl:p-0 lg:px-1 msm:py-2 lg:py-1 transition-all duration-200 hover:bg-[#0cb7d1] hover:text-[#FFFFFF]'
                } xl:p-0 lg:p-0`}
            >
              <img className='ml-1   w-5 h-5' src={selectedProperties.includes(item.value) ? tick : notick} alt='tick or notick' />
              <p className='p-1'>{item.value}</p>
            </div>
          ))}
        </div>

       
      </div>

      <div className='font-semibold  mt-2'>
        <p className='text-left lg:ml-7  msm:ml-2'> Price Range</p>
        <div className='lg:ml-8  msm:ml-4 lg:mr-0 msm:mr-4 mt-4'>
          <SliderComponent onUpdateSlider={handleSliderChange} selectedLook={selectedLook} Sub={Sub} />


        </div>
      </div>

      <div className='font-semibold  mt-2'>
        <p className='text-left lg:ml-7  msm:ml-2'> Area</p>
        <div className='lg:ml-8  msm:ml-4 lg:mr-0 msm:mr-4 mt-4'>
          <AreaSlider onUpdateSlider={handleArea} />
        </div>
      </div>

      <div className='mt-2'>
        <div>
          <p className='mb-2 lg:ml-8  msm:ml-2 text-left font-semibold'>Facings</p>
        </div>
        <div>
          <div className='lg:ml-8  msm:ml-2 mt-2'>
            {filterData.map((data: any, index: number) => (
              <div className='flex flex-wrap xl:gap-10 msm:gap-2 items-center ' key={index}>
                {data.facing.map((item: any) => (
                  <button
                    key={item.id}
                    onClick={() => handleFacing(item.id, item.button)}
                    className={`py-2 px-6 rounded-3xl 
                      ${facing.includes(item.button) ? 'bg-[#0cb7d1] text-[#FFFFFF]' : 'bg-[#F5F4F8]'} 
                      transition-all duration-200 
                      hover:bg-[#0cb7d1] hover:text-[#FFFFFF]`}
                                      >
                    {item.button}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={`'' ${selectedLook === "Rent" || selectedLook === "Lease" ? "hidden" : "block "}`}>
        <div>
          <p className='mb-2 lg:ml-8  msm:ml-2 mt-2 text-left font-semibold'>Construction Status</p>
        </div>
        <div>
          <div className='lg:ml-8  msm:ml-2 mt-2'>
            {filterData.map((data: any, index: number) => (
              <div className='flex flex-wrap xl:gap-10 msm:gap-2 items-center ' key={index}>
                {data.status.map((item: any) => (
                  <button
                    key={item.id}
                    onClick={() => handleCon(item.id, item.button)}
                    className={`py-2 px-6 rounded-3xl 
                      ${CStatus === item.id ? 'bg-[#0cb7d1] text-[#FFFFFF]' : 'bg-[#F5F4F8]'} 
                      transition-all duration-200 
                      hover:bg-[#0cb7d1] hover:text-[#FFFFFF]`}                  >
                    {item.button}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='mt-2'>

      <div className={`${CommercialVisable ? 'hidden' : 'block'}`}>
      <div>
          <p className='mb-2 lg:ml-8  msm:ml-2 text-left font-semibold'>BHK</p>
        </div>
        <div>

        <div className="lg:ml-8 msm:ml-2 mt-2">
  <div className="flex flex-wrap  ">
    {filterData[0].BHK.map((item, index) => (
      <button
      className={`py-2 px-6 rounded-3xl mx-1 mb-2 
        ${selectedBhk.includes(item.value) ? 'bg-[#0cb7d1] text-[#FFFFFF]' : 'bg-[#F5F4F8]'} 
        transition-all duration-200 
        hover:bg-[#0cb7d1] hover:text-[#FFFFFF]`}
              key={index}
        onClick={() => handleChangeBhk(item.value)}
      >
        {item.value}
      </button>
    ))}
  </div>
</div>
        </div>

      </div>
</div>
      <div className='flex mt-4 mb-5 w-[100%] justify-center items-center'>
      <button 
        onClick={handleApply} 
        className='bg-[#1063b5] px-10 py-2 mb-5 static bottom-0 rounded-full  text-[#FFFFFF]' 
        disabled={loading} // Disable button while loading
    >
        {loading ? (
            <CircularProgress size={24} color="inherit" />
        ) : (
            'Apply Filter'
        )}
    </button>
      </div>
    </Box >
  );
};

export default Filtermini;
